import React, { useState } from 'react'
import { Box, Typography, styled, Button, Grid } from "@mui/material";
import { PaperworkStyles } from '../components/paperworkProcess/paperwork/index.css';
import ModalContractCreatedAndContractSteps from '../components/modalContractCreatedAndContractSteps';

const StepsButtom = () => {

    const [openModal, setOpenModal] = useState(false);

    const styles = PaperworkStyles();

    /*  const Item = styled(Paper)(({ theme }) => ({
         backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
         color: theme.palette.text.secondary,
         paddingBottom: "0"
     })); */

    const ButtonInfo = styled(Button)((props) => ({
        width: "47%",
        fontStyle: "normal",
        boxShadow: "none",
        fontWeight: "bolder",
        textTransform: "none",
        fontSize: "1rem",
        padding: "9px 26px",
        border: "1px solid",
        borderRadius: "7px",
        lineHeight: 1.5,
        fontFamily: ['"PoppinsBold"'].join(","),
    }));

    return (
        <div className={`${styles.container}`}>
            <ModalContractCreatedAndContractSteps 
            tramitType={'aprovers'}
            openModal={openModal}
            setOpenModal={setOpenModal}
            />
            <Grid container item xs={12}>
                <Box
                    className={`${styles.stepContainerActionsButtoms} ${styles.managProc}`}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Conoce los pasos de validación y notificación que llegan al correo electrónico de los participantes
                    </Typography>

                    <ButtonInfo
                        onClick={() => setOpenModal(true)}
                        sx={{ width: '12.5rem' }}
                        variant="outlined"
                        color="primary"
                    >
                        Ingresa aquí
                    </ButtonInfo>
                </Box>
            </Grid>
        </div>
    )
}

export default StepsButtom