import React, { useEffect } from 'react';

import { Box, Grid, IconButton, Modal } from '@mui/material';
import { theme } from '../../theme/theme';
import { Close } from '@mui/icons-material';

import AddSignGif from '../../assets/img/instrucciones-asignaciondefirma.gif';

const ModalAddSignInfo = ({ openModal, setOpenModal }) => {

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: 400,
            md: 700
        },
        bgcolor: 'background.paper',
        border: `2px solid ${theme.palette.primary.xlight}`,
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center'
    };

    const closeStyle = {
        position: 'absolute',
        top: '.1rem',
        right: '.1rem'
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setOpenModal(false);
    };

    useEffect(() => {
        if (openModal) {
            handleOpen(true)
        }
    }, [openModal]);

    return (
        <Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={closeStyle}>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>

                    <Box>
                        <img src={AddSignGif} alt='pasos para asignacion de firma' />
                    </Box>
                </Box>
            </Modal>
        </Grid>
    )
}

export default ModalAddSignInfo