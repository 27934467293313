import React, { useEffect, useState } from 'react'
import { ProceduresHistoryStyles } from '../../index.css';
import FrequentProceduresCard from '../frequentProceduresCard'
import { Grid, Typography } from '@mui/material';

const FrequentProcedures = ({ data }) => {

    const styles = ProceduresHistoryStyles();
    const [frequentProcedures, setFrequentProcedures] = useState([]);

    useEffect(() => {

        if (data) {
            const frequentProcedureCal = [];

            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const exist = frequentProcedureCal.some(ele => {
                    return ele.contractType === item.contractType && ele.tramitType === item.tramitType
                });

                if (exist) {
                    const index = frequentProcedureCal.findIndex(ele => {
                        return ele.contractType === item.contractType && ele.tramitType === item.tramitType
                    });

                    frequentProcedureCal[index].qty++
                } else {
                    item.qty = 1
                    frequentProcedureCal.push(item)
                };
            };

            const sortedData = frequentProcedureCal.sort((a, b) => {
                return b.qty - a.qty
            }).filter(ele => {
                return ele.tramitType !== 'FIRMA ANF' && ele.contractType !== 'COMERCIAL'
            });

            const maxIndex = sortedData.length <= 4 ? sortedData.length : 4;
            
            setFrequentProcedures(sortedData.slice(0, maxIndex));
        };

    }, [data])

    return (
        <Grid container spacing={1}>
            {frequentProcedures?.length > 0 ? (
                <>
                    {frequentProcedures.map(ele => (
                        <FrequentProceduresCard
                            key={ele.contractID}
                            contractType={ele.contractType}
                            iContractTypeFeeID={ele.IContractTypeFeeID}
                            iSignerCount={ele.iSignerCount}
                            processType={ele.processType}
                            tramitType={ele.tramitType}
                        />
                    ))}
                </>
            ) : (
                <Typography className={styles.secondaryText}>
                    Aun no tienes trámites creados
                </Typography>
            )}
        </Grid>
    )
}

export default FrequentProcedures