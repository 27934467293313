import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from "@mui/material/FormHelperText";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";

import sign from 'jwt-encode';

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info-icon.svg";
import { ReactComponent as TextClvUniqueIcon } from "../../../../assets/icons/text-clv-unique-icon.svg";
import { ReactComponent as LogoIcon } from "../../../../assets/logo/logo.svg";
import { clearForm, clearResClvUnica, saveTramitANFInfo } from "../../../../store/formANFTramit";
import { theme } from "../../../../theme/theme";
import { checkClvUnicaFV, updateSignerStatus } from "../../../../utils/api-info-check";
import { api, to, verifiVariable } from "../../../../utils/enums";
import { checkRut } from "../../../../utils/validations";
import InfoMessage from "../../../infoMessage";
import ModalContractUploadError from "../../../modalContractUploadError";
import Spinner from "../../../spinners/Spinner";
import { VerifiedClaveUnicaStyles } from './index.css';
import { getTramitAnf, loginDemo } from "../../../../utils/api";



const CustomFormControl = styled(FormControl)((props) => ({
    [theme.breakpoints.down('md')]: {
        width: '90%',
    },

    [theme.breakpoints.up('md')]: {
        width: '60%',
    }

}));

const CustomButtom = styled(Button)((props) => ({
    width: "8rem",
    textTransform: "none",
    fontSize: ".95rem",
    fontFamily: "PoppinsBold",
    padding: "5px 80px",
    borderRadius: "7px",
    margin: '0 .5rem',
    marginBottom: '20px',
    marginTop: '20px',
    '&:disabled': {
        backgroundColor: props.theme.palette.gray.light,
        color: props.theme.palette.white.main
    },
    backgroundColor: props.theme.palette.primary.light,
    boxShadow: "none",
    color: `${props.theme.palette.white.main} !important`,
}));


const VerifiedClaveUnica = () => {
    const styles = VerifiedClaveUnicaStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sigerRef = useRef(null);
    const { contractID, signerID } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [tramitCheck, setTramitCheck] = useState(true);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [detailContract, setDetailContract] = useState(null);
    const [message, setMessage] = useState(null);
    const [code, setCode] = useState(null);
    const [status, setStatus] = useState(null);
    const {
        control,
        handleSubmit,
        setValue
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            clv: "",
            rut: "",
            term: ""
        },
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onsubmit = async (data) => {
        try {

            const { rutId } = sigerRef.current;

            setVerifyLoading(true);

            const userdata = { rut: data['rut'], password: sign(data['clv'], api.SECRET_KEY) };

            if (rutId !== data['rut']) {
                setErrorsUpload(["Problemas al verificar clave unica", "No coinciden las credenciales"]);
                setOpenModalErrors(true);
            }

            const { data: { status, message, data: clvResp } } = await checkClvUnicaFV(userdata, token);

            if (status === 'fail') {
                setErrorsUpload(["Problemas al verificar clave unica", message]);
                setOpenModalErrors(true);
            }

            if (status === 'success') await updateSignerStatus(clvResp, contractID, signerID, token);

            setVerifyLoading(false);

            if (status === 'success') navigate(to.SIGN_DNI_CAPTURE);

        } catch (error) {
            console.log('error: ', error);
            setErrorsUpload(["Problemas con el servidor", "Comunicate con un administrador del sistema"]);
            setOpenModalErrors(true);
            setVerifyLoading(false);
        }

    }

    useEffect(() => {

        let permissionStatus;

        (async () => {

            permissionStatus = await navigator.permissions.query({ name: 'camera' });

            if (permissionStatus['state'] !== 'granted') {
                // setErrorsUpload(["Acceso camara.", "Recuerde dar permisos de acceso a la camara."]);
                // setOpenModalErrors(true);
            }

        })();

    }, []);

    useEffect(() => {
        (async () => {
            setVerifyLoading(true);
            dispatch(clearResClvUnica());
            dispatch(clearForm());

            setTimeout(async () => {
                if (!verifiVariable(signerID) && !verifiVariable(contractID)) {

                    dispatch(
                        saveTramitANFInfo({
                            indexe: "restClvUnica",
                            value: { contractID, signerID },
                        })
                    );

                    const { message } = await loginDemo();

                    setVerifyLoading(false);

                    setToken(message['Authorization']);

                    dispatch(
                        saveTramitANFInfo({
                            indexe: "token",
                            value: message['Authorization'],
                        })
                    );

                }
            }, 1500);


        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractID, signerID]);

    useEffect(() => {

        (async () => {

            if (!verifiVariable(token)) {
                const { code, status, data, message } = await getTramitAnf(token, contractID);
                setDetailContract(data);
                setStatus(status);
                setCode(code);
                setMessage(message);

                if (status === 'success') {

                    dispatch(saveTramitANFInfo({
                        indexe: "detailContract",
                        value: data
                    }));

                    dispatch(saveTramitANFInfo({
                        indexe: "code",
                        value: code
                    }));

                    dispatch(saveTramitANFInfo({
                        indexe: "status",
                        value: status
                    }));
                }

            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    /* useEffect(() => {
        dispatch(getToken());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    useEffect(() => {

        if (status === 'fail' && code === 400) {
            setErrorsUpload(["Problemas al verificar el tramite", message]);
            setOpenModalErrors(true);
            setTramitCheck(true);
        }

        /* if (status === 'success' && code === 200) {
            setOpenModalErrors(false);
            setTramitCheck(false);
        } */
        // eslint-disable-next-line
    }, [status, code]);

    useEffect(() => {

        if (status === 'success') {

            setTramitCheck(false);

            const { firmantes } = detailContract;

            const signer = !verifiVariable(firmantes) ? firmantes.find((item) => item['signerID'] === signerID) : [];

            if (!verifiVariable(signer)) {

                sigerRef.current = signer;
                if (signer['signStatus'] === 'AUTHENTICATED') {
                    navigate(to.SIGN_DNI_CAPTURE);
                } else if (signer['signStatus'] === "UPLOADED") {
                    setErrorsUpload(["No necesita autenticarse usted termino su proceso.", "Usuario autenticado."]);
                    setOpenModalErrors(true);
                    setTramitCheck(true);
                }
                setValue('rut', signer['rutId']);
            } else {
                setErrorsUpload(["Problemas con el firmante.", "No existe el frimante.", "Comunicate con un administrador del sistema."]);
                setOpenModalErrors(true);
                setTramitCheck(true);
            }

        } else {
            setTramitCheck(true);
        }

        // eslint-disable-next-line
    }, [detailContract, status, signerID]);

    return (
        <form
            onSubmit={handleSubmit(onsubmit)}
            style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center'
            }}
        >
            {(verifyLoading) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={"Verificando, por favor espera"}
                    opacity={true}
                />
            )}
            <ModalContractUploadError
                openModalErrors={openModalErrors}
                setOpenModalErrors={setOpenModalErrors}
                data={errorsUpload}
                modalIcon={'warnIcon'}
            />
            <div className={styles.mainContent}>

                <Grid container spacing={2} className="content-grid">

                    <Grid item xs={12} className={styles.headerGrid}>

                        <div className='info'>

                            <div className='text'>
                                <Typography variant="h6" gutterBottom>
                                    Valida tu identidad
                                </Typography>

                                <Tooltip
                                    title={
                                        <Typography
                                            variant="subtitle1"
                                            color={["white.main"]}
                                            gutterBottom
                                        >
                                            Validar tu identidad nos ayuda a asegurar que solo tú tengas acceso a tu cuenta
                                            y tus datos. Esto protege tu información personal y mejora la seguridad
                                            de toda nuestra plataforma.
                                        </Typography>
                                    }
                                    placement="top"
                                    TransitionComponent={Fade}
                                    TransitionProps={{
                                        timeout: 600,
                                    }}
                                    arrow
                                >
                                    <InfoIcon style={{ cursor: 'help' }} />
                                </Tooltip>

                            </div>

                            <div className='barra'></div>

                        </div>

                        <TextClvUniqueIcon />
                    </Grid>
                    <Grid item xs={12} className={styles.bodyGrid}>

                        <Controller
                            name={'rut'}
                            control={control}
                            rules={{
                                required: "Este campo es requerido",
                                validate: {
                                    isRut: (value) => {

                                        if ([checkRut(value)].includes(true)) {
                                            return true
                                        } else {
                                            return 'RUT inválido'
                                        }
                                    },
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <CustomFormControl
                                        error={
                                            fieldState.error ? true : false
                                        }
                                    >
                                        <Typography
                                            variant="h6"
                                            gutterBottom>
                                            Rut
                                        </Typography>

                                        <TextField
                                            disabled
                                            type="text"
                                            error={
                                                fieldState.error
                                                    ? true
                                                    : false
                                            }
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            placeholder={"Rut"}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error
                                                    ? false
                                                    : true
                                            }
                                        >
                                            {fieldState.error
                                                ? fieldState.error.message
                                                : null}
                                        </FormHelperText>

                                        <br />
                                    </CustomFormControl>
                                );
                            }}
                        />

                        <Controller
                            name={'clv'}
                            control={control}
                            rules={{
                                required: "Este campo es requerido",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <CustomFormControl
                                        error={
                                            fieldState.error ? true : false
                                        }
                                    >
                                        <Typography
                                            variant="h6"
                                            gutterBottom>
                                            Clave única
                                        </Typography>

                                        <TextField
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            error={
                                                fieldState.error
                                                    ? true
                                                    : false
                                            }
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            placeholder={"Clave única"}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error
                                                    ? false
                                                    : true
                                            }
                                        >
                                            {fieldState.error
                                                ? fieldState.error.message
                                                : null}
                                        </FormHelperText>

                                        <br />
                                    </CustomFormControl>
                                );
                            }}
                        />

                        <InfoMessage
                            type={'info'}
                            title={'Aviso.'}
                            message={'Tu clave única solo se utilizará para verificar tu identidad al acceder a nuestra plataforma. No la almacenaremos, asegurando así tu seguridad y privacidad'}
                            iconSize={'sm'}
                        />

                        <Controller
                            name={'term'}
                            control={control}
                            rules={{
                                required: "Debe aceptar los terminos y consiciones.",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (

                                    <FormControl
                                        style={{

                                        }}
                                        error={
                                            fieldState.error ? true : false
                                        }
                                    >
                                        <FormGroup>

                                            <div className="content-check-term">
                                                <FormControlLabel control={<Checkbox onChange={onChange} value={true} />} />
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                    className="text-term"
                                                    onClick={() => window.open("https://firmavirtual.legal/wp-content/uploads/2024/05/Terminos-y-Condiciones-Generales-de-Contratacion-FIRMAVIRTUAL.LEGAL_.pdf", "_blank") /* navigate(to.TERM_CONDITIONS) */}
                                                >
                                                    Para utilizar nuestros servicios, por favor acepta nuestros términos y condiciones.
                                                </Typography>
                                            </div>

                                            <FormHelperText
                                                hidden={
                                                    fieldState.error
                                                        ? false
                                                        : true
                                                }
                                            >
                                                {fieldState.error
                                                    ? fieldState.error.message
                                                    : null}
                                            </FormHelperText>

                                            <br />
                                        </FormGroup>
                                    </FormControl>
                                );
                            }}
                        />

                        <div className="content-power-by">
                            <Typography variant="h6" gutterBottom>
                                Powered by
                            </Typography>
                            <LogoIcon />
                        </div>

                    </Grid>

                    <Grid item xs={12} className={styles.footerGrid}>
                        <CustomButtom
                            disabled={tramitCheck}
                            type='submit'
                            variant="contained"
                        >
                            Verificar
                        </CustomButtom>
                    </Grid>
                </Grid>
            </div>
        </form>
    );

}

export default VerifiedClaveUnica;
