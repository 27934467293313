import React from 'react'
import { Grid, Typography } from '@mui/material';
import CardTypeFirm from './molecules/card';
import { styles } from '../../../../../helpers';

const CardSteps = ({ data, cardMenu, cardMenuActive, setCardMenuActive, infoActive, setInfoActive }) => {

    const anfContainerSyles = {
        ...styles.card_container_maxSecurity,
        ...(cardMenu === 'approvers' && { display: 'none' })
    }

    return (
        <>
            <Grid item xs={12} md={6} lg={3} sx={styles.card_container}>

                <Grid sx={anfContainerSyles}>
                    <Typography sx={styles.card_container_title}>
                        Máxima seguridad legal
                    </Typography>
                    <CardTypeFirm
                        icon={data[0].icon}
                        title={data[0].title}
                        UrlButtom={data[0].url}
                        step={data[0].step}
                        cardMenuActive={cardMenuActive}
                        setCardMenuActive={setCardMenuActive}
                        infoActive={infoActive}
                        setInfoActive={setInfoActive}
                        cardMenu={cardMenu}
                        tramitType={data[0].tramitType}
                    />
                </Grid>

            </Grid>

            <Grid item xs={12} md={12} lg={6} sx={styles.card_container}>

                <Grid sx={styles.card_container_midSecurity}>
                    <Typography sx={styles.card_container_title}>
                        Mayor seguridad legal
                    </Typography>
                    <Grid sx={styles.card_container_midSecurity_Box}>
                        <CardTypeFirm
                            icon={data[1].icon}
                            title={data[1].title}
                            UrlButtom={data[1].url}
                            step={data[1].step}
                            cardMenuActive={cardMenuActive}
                            setCardMenuActive={setCardMenuActive}
                            infoActive={infoActive}
                            setInfoActive={setInfoActive}
                            cardMenu={cardMenu}
                            tramitType={data[1].tramitType}
                        />

                        <CardTypeFirm
                            icon={data[2].icon}
                            title={data[2].title}
                            UrlButtom={data[2].url}
                            step={data[2].step}
                            cardMenuActive={cardMenuActive}
                            setCardMenuActive={setCardMenuActive}
                            infoActive={infoActive}
                            setInfoActive={setInfoActive}
                            cardMenu={cardMenu}
                            tramitType={data[2].tramitType}
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} md={6} lg={3} sx={styles.card_container}>

                <Grid sx={styles.card_container_lowSecurity}>

                    <Typography sx={styles.card_container_title}>
                        Buena seguridad legal
                    </Typography>
                    <CardTypeFirm
                        icon={data[3].icon}
                        title={data[3].title}
                        UrlButtom={data[3].url}
                        step={data[3].step}
                        cardMenuActive={cardMenuActive}
                        setCardMenuActive={setCardMenuActive}
                        infoActive={infoActive}
                        setInfoActive={setInfoActive}
                        cardMenu={cardMenu}
                        tramitType={data[3].tramitType}
                    />

                </Grid>

            </Grid>
        </>
    )
}

export default CardSteps