import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import { ExpressProcessStyles } from "../../index.css";
import Btn from "../../../../buttons/Button";
import { saveTramitExpressInfo } from "../../../../../store/formExpressTramit";
import { verifiVariable } from "../../../../../utils/enums";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorAlert = ({ errors, title, msn, onClose }) => {
    const styles = ExpressProcessStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        dispatch(
            saveTramitExpressInfo({
                indexe: "statusMergeDoc",
                value: null,
            })
        );

        setOpen(false);

        if (!verifiVariable(onClose)) onClose();

    };

    useEffect(() => {
        if (errors > 0) handleClickOpen();
    }, [errors]);

    return (
        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            className={styles.mainContainerDialog}
        >
            <DialogTitle
                sx={{ textAlign: "center" }}
                color={["secondary.main"]}
            >
                {title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>{msn}</DialogContentText>
            </DialogContent>

            <DialogActions
                style={{ justifyContent: "center", paddingBottom: "20px" }}
            >
                <Btn
                    variant="outlined"
                    text="OK"
                    color="primary"
                    onClick={handleClose}
                    sx={{
                        height: "39px",
                        borderRadius: "20px",
                        marginLeft: "30px !important",
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ErrorAlert;
