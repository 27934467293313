import { Fragment, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRedo } from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
// import { MdFindReplace } from "react-icons/md";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import NumberFormat from 'react-number-format';

import { BreadCrumbTracker, Btn, ModalUploadNotaryDoc, Notificationbanner, Spinner } from "../..";
import { ReactComponent as UserIcon } from "./img/user-icon.svg";
import { getContractDetail, saveDetailsContract } from "../../../store/contract/contractDetail";
import { ContractDetailReviewStyles } from "./index.css";
import { to, typeUser, verifiVariable } from "../../../utils/enums";
import DetailSignatories from "./molecules/detailSignatories";
import { Context } from "../../../context/utilsContext";
import DeleteContractAlert from "./molecules/deleteContractAlert";
import { resetContractApi, sendContractPriotity, sendEmailSign } from "../../../utils/api";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import { getUserToken } from "../../storeSelector/storeSelector";
import ResetContractModal from "./molecules/resetContractModal";
import ModalContractCreatedAndContractSteps from "../../modalContractCreatedAndContractSteps";
import { Box } from "@mui/material";

import { ReactComponent as FileIcon } from "../../../assets/icons/file-icon.svg";
import { ReactComponent as IconEye } from "../../../assets/icons/icon-eye-outlined-blue-light.svg";
import { ReactComponent as IconDocumentWhite } from "../../../assets/icons/icon-document-white-outlined.svg";
import { ReactComponent as IconDownloadWhite } from "../../../assets/icons/icon-download-white-outlined.svg";
import ViewModalDocs from "../signAuthorization/molecules/viewModalDocs";
import ErrorAlert from "../expressProcess/molecules/errorAlert";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const button = {
    marginTop: `${["xs"].includes(ScreenSizeHook) ? "15px" : "0px"}`,
    fontSize: "0.8rem",
    textTransform: "none",
    backgroundColor: "quinary.light",
    color: "primary.light",
    "&:hover": {
        backgroundColor: "primary.xlight",
    },
};

const ResendContractCustomButtom = styled(Button)((props) => ({
    borderRadius: '7px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: '0.78rem',
    padding: '4px 20px',
    border: `1px solid ${props.theme.palette.primary.light}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.primary.light}`
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(','),
}));

const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    margin: 5,
    fontSize: '0.78rem',
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const CustomTitle = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontSize: '1rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomSubTitle = styled(Typography)((props) => ({
    lineHeight: '0.75',
    color: props.theme.palette.gray.dark,
    fontSize: '0.75rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomPayInfo = styled(Typography)((props) => ({
    lineHeight: '3',
    color: props.theme.palette.black.main,
    fontSize: '0.75rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));


const ContractDetailReview = () => {
    const styles = ContractDetailReviewStyles();
    const screenSizeHook = ScreenSizeHook();
    const userToken = getUserToken();
    const { loading, setLoading, setTimer, timer } = useContext(Context);
    const {
        contract: {
            contractCreator: {
                loading: loadingTramit,
                contractCreatedDetails,
                tramitAproversCreated
            }
        },
        session: {
            login: {
                user,
                userInfo
            }
        }
    } = useSelector((state) => state);
    const invalidContract = useRef(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [detailContract, setDetailContract] = useState(undefined);
    const [payInfo, setPayInfo] = useState(undefined);
    const [textLoading, setTextLoading] = useState('');
    const [showReminderMessage, setShowReminderMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [textReenvio, setTextReenvio] = useState("");
    const [reSentContract, setReSentContract] = useState(false);
    const [approveSigner, setApproveSigner] = useState([]);
    const [openModalDocument, setOpenModalDocument] = useState({
        openModal: false,
        tramitType: '',
        download: false
    });
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMsn, setAlertMsn] = useState("");
    const [showError, setShowError] = useState(0);

    /* const grillaDatosSolici = useCallback(() => {

        if (
            !verifiVariable(detailContract) &&
            !verifiVariable(detailContract['contrato']) &&
            !verifiVariable(detailContract['contrato'][0]) &&
            ['CREATED', 'REJECTED'].includes(detailContract['contrato'][0]['sStatus']) &&
            ['sm', 'xs'].includes(screenSizeHook)
        ) {
            return 12;
        } else {
            return 6;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailContract]); */

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const getTramit = () => {
        const { token } = user;

        if (typeof state === 'object') {
            dispatch(getContractDetail(state.sContractID, token));
        } else {
            dispatch(getContractDetail(state, token));
        };
    };

    const resetContract = async () => {

        try {

            setLoading(true);

            const { token } = user;

            const { status, message } = await resetContractApi(state, token);

            setLoading(false);

            if (status === 'success') {

                setShowReminderMessage(true);
                setAlertType("success");
                setAlertMessage(
                    "Se ha reiniciado el contrato"
                );

                getTramit();

            } else {
                setShowReminderMessage(true);
                setAlertType("error");
                setAlertMessage(message['msg']);
            }

            setTimeout(() => {
                setShowReminderMessage(false);
                setAlertType("");
                setAlertMessage("");
            }, 4000);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const sendReminder = async () => {

        try {

            setLoading(true);

            const { token } = user;

            if (reSentContract === false) await sendContractPriotity(state, token);

            if (reSentContract === true) await sendEmailSign(state, token);

            setLoading(false);
            setShowReminderMessage(true);
            setAlertType("success");
            setAlertMessage(
                "Se ha enviado un recordatorio a todos los firmantes"
            );
            getTramit();

            setTimeout(() => {
                setShowReminderMessage(false);
                setAlertType("");
                setAlertMessage("");
            }, 4000);

            setTimer();

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleShowDocument = (open, tramitType, download) => {
        setOpenModalDocument({
            openModal: open,
            tramitType: tramitType,
            download: download
        });
    };

    useEffect(() => {

        if (!verifiVariable(user) && !verifiVariable(state)) {
            getTramit();
        } else {
            navigate(to.DASHBOARD_WELCOME);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, state]);

    useEffect(() => {

        if (!verifiVariable(contractCreatedDetails)) {

            let total = 0;
            let iva = 0;
            let notary = 0;
            let signature = 0;

            setDetailContract(contractCreatedDetails);

            setApproveSigner(contractCreatedDetails['firmantes'].map((item) => item['agree_draft']));

            contractCreatedDetails['firmantes'].forEach((firmante) => {
                if (firmante.portion !== 0) {
                    total += parseInt(firmante.totalPay);
                    iva += parseInt(firmante.iva);
                    notary += parseInt(firmante.notaryPay);
                    signature += parseInt(firmante.amount);
                }
            });

            setPayInfo({
                total,
                iva,
                notary,
                signature,
            });

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractCreatedDetails]);

    useEffect(() => {

        if (!verifiVariable(contractCreatedDetails)) {

            const { contrato } = contractCreatedDetails;

            if (verifiVariable(contrato) || contrato.length === 0) {
                invalidContract.current = true;
                setAlertTitle("Trámite no encontrado");
                setAlertMsn("El trámite ha sido eliminado o no existe.");
                setShowError((value) => 1 + value);
            }

        }

        /* return () => {
            dispatch(saveDetailsContract({ indexe: "contractCreatedDetails", value: null }));
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractCreatedDetails]);

    return (
        <div className="content-detail__container">

            <ErrorAlert
                msn={alertMsn}
                title={alertTitle}
                errors={showError}
                onClose={() => {
                    if (invalidContract.current === true) {
                        navigate(-1);
                        dispatch(saveDetailsContract({ indexe: "contractCreatedDetails", value: null }));
                    }
                }}
            />

            <ModalContractCreatedAndContractSteps
                tramitCode={!verifiVariable(detailContract) &&
                    !verifiVariable(detailContract['contrato']) &&
                    !verifiVariable(detailContract['contrato'][0]) &&
                    detailContract?.['contrato']?.[0]?.['autoId']}
                tramitType={'aprovers'}
                openModal={tramitAproversCreated && true}
            />

            <div className="contract-details__main">

                <div className="contract-details__container">

                    <section className="contract-detail__info-container">

                        <Notificationbanner
                            display={showReminderMessage}
                            notificationType={alertType}
                        >
                            <strong>{alertMessage}</strong>
                        </Notificationbanner>

                        {(loading || loadingTramit) && (
                            <Spinner
                                loading={true}
                                type="bar"
                                text={textLoading}
                                opacity={true}
                            />
                        )}

                        {
                            !verifiVariable(detailContract) &&
                            !verifiVariable(detailContract['contrato']) &&
                            !verifiVariable(detailContract['contrato'][0]) && (<BreadCrumbTracker
                                location={pathname}
                                status={detailContract['contrato'][0]['sStatus']}
                                paymentStatus={detailContract['contrato'][0]["sPaymentStatus"]}
                                showGoback={true}
                            />)}

                        <Grid container>

                            <Dialog
                                fullWidth={true}
                                TransitionComponent={Transition}
                                open={open}
                                onClose={handleClose}
                                maxWidth='xs'
                                className={styles.mainContainerDialog}
                            >

                                <DialogTitle sx={{ textAlign: 'center' }}>
                                    ¿Estás seguro que deseas realizar esta acción?
                                </DialogTitle>

                                <DialogContent>

                                    <DialogContentText>
                                        {textReenvio}
                                    </DialogContentText>

                                </DialogContent>

                                <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                                    <Btn
                                        variant="outlined"
                                        text="Cancelar"
                                        color="error"
                                        onClick={() => handleClose()}
                                        sx={{
                                            height: '39px',
                                            borderRadius: '20px'
                                        }}
                                    />

                                    <Btn
                                        variant="outlined"
                                        text="Aceptar"
                                        color="primary"
                                        onClick={() => { sendReminder(); handleClose() }}
                                        sx={{
                                            height: '39px',
                                            borderRadius: '20px',
                                            marginLeft: '30px !important'
                                        }}
                                    />

                                </DialogActions>

                            </Dialog>

                            {/* Inicio Primera Seccion */}

                            <Grid item xs={12} sm={5} /* sm={grillaDatosSolici()} */ style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                                <div className={styles.contentTitle}>

                                    <div className={styles.titleNumber}>1</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos personales  del solicitante
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Mis datos personales
                                        </CustomSubTitle>

                                    </div>

                                </div>

                                {/* {grillaDatosSolici() && <br />} */}

                            </Grid>

                            <Grid item xs={12} sm={7} /* sm={grillaDatosSolici()} */>

                                <div className={styles.optionsContent}>

                                    {
                                        !verifiVariable(detailContract) &&
                                        !verifiVariable(detailContract['contrato']) &&
                                        !verifiVariable(detailContract['contrato'][0]) &&
                                        ['REVIEW', 'CREATED', 'REJECTED'].includes(detailContract['contrato'][0]['sStatus']) &&
                                        (
                                            <Fragment>

                                                <ResetContractModal aceptar={() => resetContract()} />

                                                {
                                                    !approveSigner.includes('SUCCESS') &&
                                                    (
                                                        <DeleteContractAlert />
                                                    )
                                                }

                                            </Fragment>
                                        )
                                    }

                                    {!verifiVariable(detailContract) &&
                                        !verifiVariable(detailContract['contrato']) &&
                                        !verifiVariable(detailContract['contrato'][0]) &&
                                        ['IN_NOTARY'].includes(detailContract['contrato'][0]['sStatus']) &&
                                        userInfo.iProfileID === typeUser.ADMIN &&
                                        (
                                            <ModalUploadNotaryDoc
                                                styledButton={button}
                                                contract={contractCreatedDetails}
                                                userToken={userToken}
                                                inList={false}
                                            />
                                        )
                                    }

                                    <Tooltip
                                        title={
                                            <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                Actualizar el seguimiento permite actualizar el estado de cada firmante.
                                            </Typography>
                                        }
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        arrow
                                    >

                                        <DeleteAndUpdateCustomButtom variant="outlined" startIcon={<FaRedo className="icon--blue" />} onClick={() => getTramit()}>
                                            Actualizar seguimiento
                                        </DeleteAndUpdateCustomButtom>

                                    </Tooltip>

                                    {/* <DeleteAndUpdateCustomButtom
                                        sx={{ marginLeft: 2.5 }}
                                        variant="outlined"
                                        startIcon={<MdFindReplace className="icon--blue" />}
                                        onClick={() => navigate(to.CONTRACT_REPLACE_DOCUMENT)}>
                                        Reemplazar documento
                                    </DeleteAndUpdateCustomButtom> */}
                                </div>

                            </Grid>

                            {/* Fin Primera Secciοn e Inicio Segunda Session */}

                            <Grid item xs={12}>

                                <div className={styles.userInfoContent}>

                                    <div className={styles.userIcon}>
                                        <UserIcon />
                                    </div>

                                    <div className={styles.userTitleInfo}>

                                        <Typography variant="subtitle1" gutterBottom>
                                            Nombre
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            Correo electrónico
                                        </Typography>

                                        <Typography variant="subtitle1" gutterBottom>
                                            Teléfono
                                        </Typography>

                                    </div>

                                    {
                                        !verifiVariable(detailContract) &&
                                        !verifiVariable(detailContract['contrato']) &&
                                        !verifiVariable(detailContract['contrato'][0]) &&
                                        (<div className={styles.userInfo}>

                                            <Typography variant="subtitle1" gutterBottom>
                                                {detailContract['owner']['full_name']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                {detailContract['owner']['email']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                {detailContract['owner']['phone'] || 'No especificado'}
                                            </Typography>

                                        </div>)}

                                </div>

                            </Grid>

                            <Grid item xs={12}>

                                <div className={styles.contentTitle} style={{ marginTop: '100px' }}>

                                    <div className={styles.titleNumber}>2</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos del trámite
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Datos del trámite a realizar
                                        </CustomSubTitle>

                                    </div>

                                </div>

                            </Grid>

                            <Grid item xs={12} md={6}>

                                <div className={styles.userInfoContent}>

                                    <div className={`${styles.infoTramitContent}`}>

                                        <div className={`${styles.titleInfoTramite} ${styles.userTitleInfo}`}>

                                            <Typography variant="subtitle1" gutterBottom>
                                                ID de trámite
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Tipo de trámite
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Trámite a gestionar
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Participantes
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Tipo de firma
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                Nombre del trámite
                                            </Typography>
                                        </div>

                                    </div>

                                    {
                                        !verifiVariable(detailContract) &&
                                        !verifiVariable(detailContract['contrato']) &&
                                        !verifiVariable(detailContract['contrato'][0]) &&
                                        (<div className={styles.userInfo}>

                                            <Typography variant="subtitle1" gutterBottom>
                                                {detailContract['contrato'][0]['autoId']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                                {detailContract['contrato'][0]['sTipoContrato']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                                {detailContract['contrato'][0]['sClaseContrato']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom>
                                                {detailContract['contrato'][0]['iSignerCount']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                                {detailContract['contrato'][0]['sTipoFirma']}
                                            </Typography>

                                            <Typography variant="subtitle1" gutterBottom className="text--lowercase first-letter--uppercase">
                                                {detailContract['contrato'][0]['sContractName'] || 'No especificado'}
                                            </Typography>
                                        </div>)}

                                </div>

                            </Grid>

                            <Grid item xs={12}>

                                <div className={styles.contentTitle} style={{ marginTop: '80px' }}>

                                    <div className={styles.titleNumber}>3</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            {!verifiVariable(detailContract) &&
                                                !verifiVariable(detailContract['contrato']) &&
                                                !verifiVariable(detailContract['contrato'][0]) &&
                                                detailContract?.contrato?.[0]?.sStatus === 'FINISHED'
                                                ? 'Descargar documento'
                                                : 'Documento a firmar'}
                                        </CustomTitle>

                                    </div>

                                </div>

                                <Box className={styles.infoDoc}>
                                    {

                                        (<Box>

                                            {
                                                !verifiVariable(detailContract) &&
                                                !verifiVariable(detailContract['archivos']) &&
                                                !verifiVariable(detailContract['archivos'][0]) &&
                                                (<FileIcon />)
                                            }

                                            <Typography
                                                className={styles.detailTextNameDoc}>
                                                {
                                                    !verifiVariable(detailContract) &&
                                                    !verifiVariable(detailContract['archivos']) &&
                                                    !verifiVariable(detailContract['archivos'][0]) &&
                                                    detailContract?.archivos?.[0].file_name
                                                }
                                            </Typography>
                                        </Box>)
                                    }

                                    <Box className={styles.infoDoc_buttons}>
                                        {/* <InfoDocs loading={(e) => setLoading(e)} textLoading={(e) => setTextLoading(e)} restUpDoc={() => getTramit()} /> */}

                                        <ViewModalDocs
                                            openModal={openModalDocument.openModal}
                                            setOpenModal={setOpenModalDocument}
                                            download={openModalDocument.download}
                                            tramitType={openModalDocument.tramitType}
                                        />

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['archivos']) &&
                                            !verifiVariable(detailContract['archivos'][0]) &&
                                            (<Tooltip
                                                title='Ver documento'
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                arrow
                                            >
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<IconEye />}
                                                    onClick={() => handleShowDocument(true, 'approvers', true)}
                                                    size="small"
                                                    className={styles.buttonTramit}
                                                >
                                                    Ver documento
                                                </Button>
                                            </Tooltip>)
                                        }

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['archivos']) &&
                                            !verifiVariable(detailContract['archivos'][0]) &&
                                            (<Tooltip
                                                title="Descargar contrato"
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                    timeout: 600,
                                                }}
                                                arrow
                                            >
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<IconDownloadWhite />}
                                                    onClick={() => handleShowDocument(true, 'approvers', true)}
                                                    size="small"
                                                    className={styles.buttonTramit2}
                                                >
                                                    Descargar
                                                </Button>
                                            </Tooltip>)
                                        }

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            !["FINISHED", "IN_NOTARY", "SIGNED"].includes(detailContract["contrato"][0]["sStatus"]) && (<Tooltip
                                                title="Reemplazar / Editar documento"
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                    timeout: 600,
                                                }}
                                                arrow
                                            >
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<IconDocumentWhite />}
                                                    onClick={() => navigate(to.CONTRACT_REPLACE_DOCUMENT)}
                                                    size="small"
                                                    className={styles.buttonTramit2}
                                                >
                                                    Reemplazar / Editar
                                                </Button>
                                            </Tooltip>)}
                                    </Box>
                                </Box>

                            </Grid>

                            {/* Fin Segunda Seccion e Inicio de Tercera Seccion */}

                            <Grid item xs={12} sm={6}>

                                <div className={styles.contentTitle} style={{ marginTop: '80px' }}>

                                    <div className={styles.titleNumber}>4</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Datos de los participantes
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Datos de las personas que firman los documentos
                                        </CustomSubTitle>

                                    </div>

                                </div>

                            </Grid>

                            {
                                !verifiVariable(detailContract) &&
                                !verifiVariable(detailContract['contrato']) &&
                                !verifiVariable(detailContract['contrato'][0]) &&
                                ['REVIEW', 'CREATED'].includes(detailContract['contrato'][0]['sStatus']) &&
                                detailContract['contrato'][0]['withDocument'] === 1 &&
                                (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-end'
                                        }}
                                    >

                                        {screenSizeHook === "xs" && <br />}

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'end'
                                            }}
                                        >

                                            <Tooltip
                                                title={
                                                    <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                        Opción para reenviar el el contrato a todos los participantes.
                                                    </Typography>
                                                }
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                arrow
                                            >

                                                <ResendContractCustomButtom
                                                    onClick={() => {
                                                        setReSentContract(false);
                                                        setTextReenvio('Si reenvías el contrato a los participantes, todos los firmantes deben realizar el proceso de revisión del documento nuevamente.');
                                                        handleClickOpen();
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={timer !== '00:00:00' ? true : false}
                                                    startIcon={
                                                        <BsEnvelope className='icon--blue icon--xs' />
                                                    }
                                                >
                                                    Reenviar contrato {timer !== '00:00:00' && timer}
                                                </ResendContractCustomButtom>

                                            </Tooltip>

                                        </div>

                                    </Grid>
                                )
                            }

                            {
                                !verifiVariable(detailContract) &&
                                !verifiVariable(detailContract['contrato']) &&
                                !verifiVariable(detailContract['contrato'][0]) &&
                                ["SIGNED_PENDING"].includes(detailContract['contrato'][0]['sStatus']) &&
                                detailContract['contrato'][0]['withDocument'] === 1 && (!verifiVariable(userInfo) && userInfo.iProfileID === typeUser.ADMIN) &&
                                (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-end'
                                        }}
                                    >

                                        {screenSizeHook === "xs" && <br />}

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'end'
                                            }}
                                        >

                                            <Tooltip
                                                title={
                                                    <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                        Reenvia correo de firmas, los participantes deberán realizar el proceso de firma nuevamente.
                                                    </Typography>
                                                }
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 600 }}
                                                arrow
                                            >

                                                <ResendContractCustomButtom
                                                    onClick={() => {
                                                        setReSentContract(true);
                                                        setTextReenvio('Todos los firmantes recibiran nuevamente el correo de firma.');
                                                        handleClickOpen();
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={timer !== '00:00:00' ? true : false}
                                                    startIcon={
                                                        <BsEnvelope className='icon--blue icon--xs' />
                                                    }
                                                >
                                                    Reenviar firmas {timer !== '00:00:00' && timer}
                                                </ResendContractCustomButtom>

                                            </Tooltip>

                                        </div>

                                    </Grid>
                                )
                            }

                            <Grid item xs={12}>

                                {
                                    !verifiVariable(detailContract) &&
                                    !verifiVariable(detailContract['contrato']) &&
                                    !verifiVariable(detailContract['contrato'][0]) &&
                                    (<div className={`info-body padding-y--xxs flex--evenly ${styles.contentParticipant}`}>

                                        {detailContract['firmantes'].map((member, index) => {
                                            return (
                                                <DetailSignatories
                                                    textLoading={(e) => setTextLoading(e)}
                                                    loading={(e) => setLoading(e)}
                                                    updateContract={() => getTramit()}
                                                    member={member}
                                                    index={index}
                                                    comentarios={detailContract['comentarios']}
                                                    contractDetail={detailContract['contrato']}
                                                    contractMembers={detailContract['firmantes']}
                                                    contractFiles={detailContract['archivos']}
                                                    removeSignner={false}
                                                    deleteSigner={() => console.log('displayDeleteSignnerWarning')}
                                                />
                                            );
                                        })}

                                    </div>)}

                            </Grid>


                            {/* Fin Tercera Secciοn e Inicio Cuarta Session */}

                            <Grid item xs={12}>

                                <div className={styles.contentTitle} style={{ marginTop: '100px' }}>

                                    <div className={styles.titleNumber}>5</div>

                                    <div>

                                        <CustomTitle variant="h6" gutterBottom>
                                            Resúmen del pedido
                                        </CustomTitle>

                                        <CustomSubTitle variant="subtitle1" gutterBottom>
                                            Total a pagar
                                        </CustomSubTitle>

                                    </div>

                                </div>

                                {
                                    !verifiVariable(detailContract) &&
                                    !verifiVariable(detailContract['contrato']) &&
                                    !verifiVariable(detailContract['contrato'][0]) &&
                                    <Grid item xs={12} className={styles.fourtSectionContent}>

                                        <div className={styles.payInfoMainContent}>

                                            <div className={styles.payInfoContent}>

                                                <div>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        Servicio de notaría
                                                    </CustomPayInfo>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        Servicio de firma electrónica
                                                    </CustomPayInfo>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        IVA
                                                    </CustomPayInfo>

                                                </div>

                                                <div>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['notary']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomPayInfo>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['signature']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomPayInfo>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['iva']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomPayInfo>

                                                </div>

                                            </div>

                                            <div className={styles.payTotalInfo}>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    Total
                                                </CustomPayInfo>

                                                <CustomPayInfo variant="subtitle1" gutterBottom>
                                                    <NumberFormat
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                        value={payInfo['total']}
                                                        displayType={"text"}
                                                        renderText={(value, props) => value}
                                                    />
                                                </CustomPayInfo>


                                            </div>

                                        </div>

                                    </Grid>}

                            </Grid>

                            {/* Fin Cuarta Seccion */}

                        </Grid>

                    </section>

                </div>

            </div>

        </div >

    );
}

export default ContractDetailReview;