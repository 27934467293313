import { theme } from "../theme/theme";

const styles = {
    itemFlex: (size) => {
        return {
            flex: size,
        };
    },
    paddingY: (size) => {
        return {
            py: size,
        };
    },
    buttonBlueStatusMenu: {
        width: '7.813rem',
        backgroundColor: "primary.light",
        color: "white.main",
        textTransform: "none",
        borderRadius: "7px",
        fontSize: '.813rem',
        padding: ".5rem 1rem",
        fontWeight: "700",
        margin: '0 0 .688rem 0',
        "&:hover": {
            backgroundColor: "primary.dark",
        },
    },
    buttonStatusMenu: {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
        },
    },
    buttonGridStatusMenu: {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
            backgroundColor: "primary.dark",
        },
    },
    buttonGoToCreatorMenu: {
        backgroundColor: "quaternary.light",
        color: "white.main",
        textTransform: "none",
        fontWeight: "500",
    },

    userNameStyles: {
        color: "black.main",
        textTransform: "capitalize",
        lineHeight: 1.1,
        fontSize: "15px",
    },
    titleStyles: {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "24px",
    },
    subTitleStyles: {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0"
    },
    gridContainerStyles: {
        border: "none",
    },
    containerWrapperItemsCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    boldedTextExtraLarge: {
        fontWeight: "500",
        fontSize: "2.5rem",
        color: "black.main",
    },
    boldedTextLarge: {
        fontWeight: "500",
        fontSize: "2rem",
        color: "black.main",
    },
    boldedTextSmall: {
        fontWeight: "500",
        fontSize: "1.2rem",
        color: "black.main",
    },
    boldedTextSmaller: {
        fontWeight: "500",
        fontSize: "1rem",
        color: "black.main",
    },
    boldedTextSmallest: {
        fontWeight: "500",
        fontSize: "0.9rem",
        color: "black.main",
    },
    fullContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    userNameContainerStyles: {
        marginLeft: "10px",
        display: "flex",
        flexDirection: "column",
    },
    userNickNameStyles: {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
        fontSize: "0.9rem",
    },
    body_welcome: {
        padding: "48px 64px"
    },
    title_welcome: {
        fontWeight: "300",
        textAlign: "left",
        fontSize: "1.8rem",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    subTitle_welcome: {
        fontWeight: "300",
        fontSize: "1.1rem",
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: 'center'
    },
    choiseBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    choiseBox_title: {
        fontSize: "1.6rem",
        margin: '.5rem'
    },
    infoMessageBox_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        margin: '0'
    },
    choiseBox_button: {
        width: '17rem',
        padding: '.5rem 0',
        margin: '.75rem 2rem',
        backgroundColor: 'gray.light',
        textTransform: 'none',
        color: 'white.main',
        fontFamily: 'PoppinsRegular',
        borderRadius: '5px',
        "&:hover": {
            color: 'white.main',
            backgroundColor: 'gray.main',
        }
    },
    choiseBox_button_active: {
        width: '17rem',
        padding: '.5rem 0',
        margin: '.75rem 2rem',
        backgroundColor: 'primary.main',
        textTransform: 'none',
        color: "white.main",
        fontFamily: 'PoppinsRegular',
        borderRadius: '5px',
        "&:hover": {
            color: 'white.main',
            backgroundColor: 'primary.light',
        }
    },
    infoMessageBox_info: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderWidth: '1px 1px 1px 3px',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        backgroundColor: theme.palette.primary.xxlight,
        padding: '.75rem 1.5rem',
        margin: '.5rem 0',
        position: 'relative'
    },
    infoMessageBox_info_close: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    card_welcome: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '1rem 0'
    },
    card_container: {
        display: 'flex',
        justifyContent: 'center'
    },
    card_container_title: {
        fontSize: '0.875rem',
        margin: '0 0 0.313rem 0'
    },
    card_container_maxSecurity: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `2px solid #36E08E`,
        borderRadius: '10px',
        background: theme.palette.white.main,
        width: '12rem',
        padding: '.625rem',
        margin: {
            xs: '0 0 .938rem 0',
            lg: '0'
        }
    },
    card_container_midSecurity: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: "2px solid transparent",
        borderRadius: '10px',
        background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, #dde13f, #DC8558) border-box",
        width: {
            xs: '22rem',
            md: '42rem'
        },
        padding: '.625rem',
        margin: {
            xs: '0 0 .938rem 0',
            lg: '0'
        }
    },
    card_container_midSecurity_Box: {
        display: 'flex',
        justifyContent: 'space-between',
        "& > :first-of-type": {
            margin: {
                xs: '0 .5rem 0 0',
                lg: '0 auto 0 0'
            }
        },
        width: {
            xs: '100%',
            xl: '80%'
        }
    },
    card_container_lowSecurity: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: "2px solid #DC8558",
        borderRadius: '10px',
        background: theme.palette.white.main,
        width: '12rem',
        padding: '.625rem',
    },
    card_item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '10.5rem',
    },
    card_item_ANF: {
        border: `2px solid #1DB45B`,
        borderRadius: '10px',
        backgroundColor: '#E7FFF3'
    },
    card_item_Prn: {
        backgroundColor: '#E7FFF3',
        border: `2px solid #36E08E`,
        borderRadius: '10px',
    },
    card_item_Crn: {
        backgroundColor: '#FBF6F3',
        border: `2px solid #DCE034`,
        borderRadius: '10px',
    },
    card_item_Fes: {
        backgroundColor: '#FBF6F3',
        border: `2px solid #DC8558`,
        borderRadius: '10px',
    },
    card_item_info_icon: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        width: '100%',
        padding: '.2rem .5rem',
        cursor: 'pointer',
        "& > p": {
            fontSize: '0.813rem'
        }
    },
    card_item_description: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card_item_description_text: {
        fontWeight: 800,
        fontSize: '1.2rem',
        color: theme.palette.black.secondary
    },
    card_item_title: {
        textAlign: 'center',
        fontFamily: "PoppinsBold",
        width: '7.85rem',
        lineHeight: '1rem',
        margin: '0'
    },
    card_item_title_text: {
        textAlign: 'center',
        fontSize: '0.813rem',
        color: theme.palette.black.secondary
    },
    card_item_info: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 0 0.938rem 0'
    },
    card_item_info_text: {
        alignItems: 'center',
        fontSize: "0.85rem",
        fontWeight: 500,
        marginLeft: '.3rem'
    },
    card_item_popover_container: {
        backgroundColor: theme.palette.primary.xxxxxlight,
        borderRadius: '7px',
        border: `1px solid ${theme.palette.primary.main}`,
        padding: '1.188rem 1.375rem',
        textAlign: 'center'
    },
    card_item_popover_title: {
        fontSize: '1rem',
        fontFamily: 'PoppinsBold',
        width: '12rem',
        margin: '0 auto .781rem auto'
    },
    card_item_popover_textBox: {
        margin: '0 0 .5rem 0',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            "& > :first-of-type": {
                marginRight: '.5rem'
            }
        }
    },
    card_item_popover_mainText: {
        fontSize: '1.25rem',
        color: theme.palette.primary.main,
        fontFamily: 'PoppinsBold'
    },
    card_item_popover_secondaryText: {
        fontSize: '.875rem',
        color: theme.palette.black.main,
        fontFamily: 'PoppinsBold'
    },
    card_item_popover_button: {
        marginTop: '.5rem',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.white.main,
        padding: '.813rem 1.25rem',
        borderRadius: '.438rem',
        textTransform: 'none',
        fontSize: '.813rem',
        fontFamily: 'PoppinsBold',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    contract_creation_welcome: {
        padding: "15px 0",
        display: "flex",
        justifyContent: "center",
        marginBottom: '34px'
    },
    text_soport_welcome: {
        display: "flex",
        fontSize: "1.3rem",
        fontWeight: "300",
        alignItems: "center"
    },
    text_service_welcome: {
        display: "flex",
        fontSize: "1.4rem",
        fontWeight: "300",
        alignItems: "center",
        paddingTop: "15px",
        justifyContent: "center",
    },
    userAvatarContainerStyles: {
        display: "flex",
        flexDirection: "row",
    },
    userAvatarPictureStyles: {
        overflow: "hidden",
        borderRadius: "10%",
    },
};

export default styles;
