import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import * as PdfjsLib from "pdfjs-dist";
import { degrees } from "pdf-lib";

import { ExpressProcessStyles } from "../../index.css";
import AddSign from "../addSign";
import { saveTramitExpressInfo } from "../../../../../store/formExpressTramit";
import { randomRgba, verifiVariable } from "../../../../../utils/enums";
import DraggableBox from "../draglableBox";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import HeaderAddSignInfo from "./header";

const ControlsCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    background: props.theme.palette.primary.light,
    marginTop: "11px",
    marginBottom: "20px",
    padding: "14px 22px",
    "&:hover": {
        background: props.theme.palette.primary.light,
    },
    "&:disabled": {
        background: props.theme.palette.gray.dark,
        color: props.theme.palette.white.main,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const CustomTextControls = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontWeight: 100,
    marginLeft: "25px",
    marginRight: "25px",
}));

const PreviewDocument = ({ firmantes }) => {
    const {
        formExpressTramit: { document, participants },
    } = useSelector((state) => state);
    const screenSizeHook = ScreenSizeHook();
    const styles = ExpressProcessStyles();
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [fontSize, setFontSize] = useState(40);
    const refCanvas = useRef();
    const refPdfViewer = useRef();
    const [docPdf, setDocPdf] = useState(null);
    const refInputControl = useRef();
    const [layerX, setLayerX] = useState(0);
    const [layerY, setLayerY] = useState(0);
    const [openSigModal, setOpenSigModal] = useState(false);
    const [firms, setFirms] = useState({});
    const [list, setList] = useState([]);
    const [viewport, setViewport] = useState(null);
    const { watch, setValue } = useForm({
        defaultValues: {
            currentPage: 1,
        },
    });

    let currentPage = watch("currentPage");

    const buildId = (length) => {
        let result = "";
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    };

    const boxDelete = (id) => {
        const objectSignet = { ...firms };

        delete objectSignet[id];

        setFirms(objectSignet);
    };

    const handleClick = (event) => {
        const canvas = event.target;
        const rect = canvas.getBoundingClientRect();
        const canvasWidth = canvas.width;

        setLayerY(rect.height / 2);

        setLayerX(rect.right - canvasWidth / 2);
    };

    const addFirmante = (signer) => {
        if ([null, undefined, ""].includes(signer)) return;

        const parseSigner = JSON.parse(signer);

        setFirms((itemList) =>
            Object.assign({
                ...itemList,
                [buildId(4)]: {
                    numbSigned: parseSigner["sign"] + 1,
                    text: `[[s|${parseSigner["sign"]}]]`,
                    textTootip: parseSigner["fullName"],
                    rut: parseSigner["rut"],
                    x: layerX,
                    y: layerY,
                    positionBoxX: layerX,
                    positionBoxY: layerY,
                    size: fontSize,
                    textColor: parseSigner["textColor"],
                    page: currentPage,
                    rotate: degrees(0),
                },
            })
        );
    };

    const openModal = (e) => {
        const element = e;

        const nodeName = element["target"]["nodeName"];

        if (!["CANVAS"].includes(nodeName)) return;

        handleClick(e);

        setOpenSigModal(true);
    };

    const goPrevious = () => {
        if (docPdf === null || currentPage === 1) return;
        setValue("currentPage", (currentPage -= 1));
    };

    const goNext = () => {
        if (docPdf == null || currentPage >= docPdf._pdfInfo.numPages) return;
        setValue("currentPage", (currentPage += 1));
    };

    useEffect(() => {
        let windowWidth = window.innerWidth;
        window.addEventListener("resize", () => {
            if (window.innerWidth !== windowWidth) {// Hemos detectado un cambio en el tamaño del navegador, por esta razón debe reasignar las firmas.
                setFirms({});
                windowWidth = window.innerWidth;
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (![null, undefined, ""].includes(document)) {
                let loadPdfDocumentTask = PdfjsLib.getDocument(document);

                let pdf = await loadPdfDocumentTask.promise;

                setDocPdf(pdf);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);

    useEffect(() => {
        setList([...participants].filter((item) => item["rol"].includes("Firmante")));
        setFirms({});
    }, [participants]);

    useEffect(() => {
        if (!verifiVariable(firmantes)) {
            setList([...firmantes].filter((item) => item["rol"] === 0).map((item) => Object.assign({ ...item, rut: item['RUT'], fullName: item['full_name'], textColor: randomRgba() })));
            setFirms({});
        }
    }, [firmantes]);

    useEffect(() => {
        (async () => {
            const parsetCurrentPage = parseInt(currentPage);

            let zoomScale = 0;

            if (
                [null, undefined, ""].includes(docPdf) ||
                [0, null, undefined].includes(parsetCurrentPage) ||
                parsetCurrentPage > docPdf._pdfInfo.numPages
            )
                return;

            let page = await docPdf.getPage(parsetCurrentPage);

            let canvas = refCanvas.current;
            let ctx = canvas.getContext("2d");

            const getViewport = page.getViewport({ scale: .75 });

            const isLandscape = getViewport.width > getViewport.height;

            if (['lg', 'xl'].includes(screenSizeHook)) {

                let scaleLg = .80;

                if (isLandscape === true) scaleLg = .60;

                zoomScale = scaleLg;
            }

            if (['md', 'sm', 'xs'].includes(screenSizeHook)) {

                let scaleMd = .45;

                if (isLandscape === true) scaleMd = .35;

                zoomScale = scaleMd;

            }

            let viewport = page.getViewport({ scale: zoomScale });

            setViewport(viewport);

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            page.render({
                canvasContext: ctx,
                viewport: viewport,
            });

            viewport = page.getViewport({ scale: zoomScale });

            window["jQuery"](refPdfViewer.current)
                .width(viewport.width)
                .height(viewport.height);

            window["jQuery"](refInputControl.current).width(
                viewport.width - 151.5 * 2
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdf, currentPage, screenSizeHook]);

    useEffect(() => {
        dispatch(
            saveTramitExpressInfo({
                indexe: "firms",
                value: firms,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firms]);

    return (
        <Box className={`${styles.contentDocuments} `}>
            <AddSign
                key={"flkafkje"}
                openSigModal={openSigModal}
                setOpenSigModal={(e) => setOpenSigModal(e)}
                signatory={(e) => addFirmante(e)}
                signatories={list}
            />

            <Box sx={{ marginBottom: '1rem' }}>
                <HeaderAddSignInfo />
            </Box>

            <div
                id="my_pdf_viewer"
                className={styles.pdfViewer}
                ref={refPdfViewer}
                onClick={(e) => openModal(e)}
            >
                {!verifiVariable(docPdf) &&
                    Object.keys({ ...firms }).map((item) => {
                        return (
                            firms[item]["page"] === currentPage && (
                                <DraggableBox
                                    key={item}
                                    positions={(element) =>
                                        setFirms((itemList) =>
                                            Object.assign(
                                                { ...itemList },
                                                { [item]: element }
                                            )
                                        )
                                    }
                                    deleteBox={() => boxDelete(item)}
                                    refCanvas={refCanvas}
                                    signatory={firms[item]}
                                    viewport={viewport}
                                />
                            )
                        );
                    })}

                <canvas id="pdf_renderer" ref={refCanvas}></canvas>
            </div>

            <div id="navigation_controls" className={styles.navigationControls}>
                <ControlsCustomButtom
                    disabled={currentPage === 1}
                    onClick={() => goPrevious()}
                    variant="contained"
                    disableElevation
                >
                    Página Anterior
                </ControlsCustomButtom>

                {!["xs", "sm", "md"].includes(screenSizeHook) && (
                    <CustomTextControls variant="h6" gutterBottom>
                        {currentPage}-{docPdf ? docPdf._pdfInfo.numPages : 0}
                    </CustomTextControls>
                )}

                <ControlsCustomButtom
                    disabled={
                        [null, undefined, ""].includes(docPdf)
                            ? false
                            : currentPage === docPdf._pdfInfo.numPages
                    }
                    onClick={() => goNext()}
                    variant="contained"
                    disableElevation
                >
                    Página Siguiente
                </ControlsCustomButtom>
            </div>
        </Box>
    );
};

export default PreviewDocument;
