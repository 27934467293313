import React, { useEffect, useState } from 'react'

import { Grid, Typography } from '@mui/material';

import LastProceduresCard from '../lastProceduresCard';
import { ProceduresHistoryStyles } from '../../index.css';

const LastProcedures = ({ data }) => {

    const [lastProcedures, setLastProcedures] = useState([]);
    const styles = ProceduresHistoryStyles();

    useEffect(() => {

        if (data) {
            const newData = data.filter(ele => {
                return ele.tramitType !== 'FIRMA ANF' && ele.contractType !== 'COMERCIAL'
            });

            const maxIndex = newData.length <= 4 ? newData.length : 4;

            setLastProcedures(newData.slice(0, maxIndex));
        };

    }, [data])

    return (
        <Grid container spacing={1}>
            {lastProcedures?.length > 0 ? (
                <>
                    {lastProcedures.map(ele => (
                        <LastProceduresCard
                            key={ele.contractID}
                            autoID={ele.autoID}
                            contractID={ele.contractID}
                            contractType={ele.contractType}
                            IcontractTypeFeeID={ele.IcontractTypeFeeID}
                            iSignedCount={ele.iSignedCount}
                            iSignerCount={ele.iSignerCount}
                            processType={ele.processType}
                            sPaymentStatus={ele.sPaymentStatus}
                            status={ele.status}
                            tramitType={ele.tramitType}
                        />
                    ))}
                </>
            ) : (
                <Typography className={styles.secondaryText}>
                    Aun no tienes trámites creados
                </Typography>
            )}
        </Grid>
    )
}

export default LastProcedures