import { Fragment, createRef, forwardRef, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

import { ReactComponent as CameraIcon } from "../../icons/camera-icon.svg";
import { ReactComponent as PlayIcon } from "../../icons/play-icon.svg";

import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { SignAuthorizationStyles } from "../../index.css";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CameraButton = styled(Button)((props) => ({
    width: "20%",
    alignSelf: "flex-end",
    marginTop: "10px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    padding: "13.5px 0px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const VideoVerifyModal = ({ fileVideo }) => {
    const videoRef = createRef();
    const styles = SignAuthorizationStyles();
    const screenSizeHook = ScreenSizeHook();
    const [open, setOpen] = useState(false);

    const handlePlay = () => {
        videoRef.current.play();
    };

    const handlePause = () => {
        videoRef.current.pause();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        handlePause();
        setOpen(false);
    };

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <Fragment>
            <Tooltip
                title={
                    <Typography
                        variant="subtitle1"
                        color={[
                            "white.main",
                        ]}
                        gutterBottom
                    >
                        Verficar video.
                    </Typography>
                }
                TransitionComponent={
                    Fade
                }
                TransitionProps={{
                    timeout: 600,
                }}
                arrow
            >
                <CameraButton
                    className="camera-icon"
                    onClick={handleClickOpen}
                    variant="contained"
                    color="primary"
                >
                    <CameraIcon />
                </CameraButton>
            </Tooltip>

            <Dialog
                fullScreen={screenSizeHook !== "xs" ? false : true}
                maxWidth={screenSizeHook !== "xs" ? "xs" : false}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <BootstrapDialogTitle onClose={handleClose} />

                <DialogTitle className={styles.titleVideoSign}>
                    {"Video de verificación de identidad"}
                </DialogTitle>
                <DialogContent className={styles.contentDialogVideo}>
                    <div className={styles.constentPlayVideo}>
                        <video ref={videoRef}>
                            <source
                                src={`https://anf.firmavirtual.legal/api/download/${fileVideo}`}
                            ></source>
                        </video>

                        <PlayIcon onClick={handlePlay} />

                        <div className="content-info">
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                className="played"
                            >
                                Reproducir video
                            </Typography>
                        </div>
                    </div>

                    <Divider style={{ marginTop: "100px" }} />

                    <div className={styles.textFooter}>
                        <DialogContentText>
                            El siguiente video fue grabado por el firmante, es{" "}
                            <br />
                            <span>importante</span> que deba mencionar lo
                            siguiente:
                        </DialogContentText>

                        <br />

                        <DialogContentText>
                            Con plena voluntad, yo, [Nombre completo + debes
                            mostrar su Identificación], firmo el contrato
                            [Nombre del contrato] junto con [Nombres de los
                            demás firmantes]
                        </DialogContentText>

                        <br />
                        <br />

                        <DialogContentText>
                            de lo contrario el tramite será rechazado.
                        </DialogContentText>
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default VideoVerifyModal;
