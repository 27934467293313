import { Fragment, useContext, useEffect, useMemo, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import FilledInput from "@mui/material/FilledInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Collapse from "@mui/material/Collapse";

import { setearFecha, verifiVariable } from "../../../utils/enums";
import Rows from "./molecules/rows";
import TablePaginationActions from "./molecules/tablePagination";
import EnhancedTableHead from "./molecules/tableHead";
import { Context } from "../../../context/utilsContext";
import DeleteContractAlert from "./molecules/deleteContractAlert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const createData = (
    // 1697646314
    sProcess,
    sContractID,
    autoId,
    sContractName,
    tramit,
    sStatus,
    dContractDT,
    withDocument,
    iSignedCount
) => {
    const date = new Date(setearFecha(dContractDT));

    const unixTimestamp = Math.floor(date.getTime() / 1000);

    return {
        sProcess,
        dateFilter: unixTimestamp,
        autoIdFilter: parseInt(autoId.replace(/(ASN|AP)/g, "")),
        sContractID,
        action: "Acciones",
        autoId,
        sContractName,
        typeTramit: tramit["sTipoContrato"],
        tramit,
        sStatus,
        dContractDT,
        information: [
            {
                autoId,
                dContractDT,
                withDocument,
                iSignedCount,
            },
        ],
    };
};

const defaultValueFilterBy = "Filtrar por";

const GeneralListProced = ({ dataGrid, updateList }) => {
    const { show } = useContext(Context);
    const [filterValue, setFilterValue] = useState("");
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("ID");
    const [rows, setRows] = useState([]);
    const [idContract, setIdContract] = useState("");
    const [openModalDelete, setOpenModalDelete] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterBy, setFilterBy] = useState();
    const [countRows, setCountRows] = useState(0); //sProcess
    const [sProcess, setSProcess] = useState("");

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
        setPage(0);
    };

    const handleChange = (event) => {
        setFilterBy(event.target.value);
        // if(event.target.value !== "sStatus")
        setFilterValue("");
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // eslint-disable-next-line
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }

            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    };

    const descendingComparator = (a, b, orderBy) => {

        const valueA = a[orderBy] || 'No especificado';
        const valueB = b[orderBy] || 'No especificado';

        if (valueB < valueA) {
            return -1;
        };
        if (valueB > valueA) {
            return 1;
        };
        return 0;
    };

    const getComparator = (order, orderBy) => {
        let pattern = "";

        if (orderBy === "Tramite") {
            pattern = "typeTramit";
        } else if (orderBy === "ID") {
            pattern = "autoIdFilter";
        } else if (orderBy === "Fecha") {
            pattern = "dateFilter";
        } else if (orderBy === "Nombre del contrato") {
            pattern = "sContractName";
        } else {
            pattern = "Acciones";
        };

        return order === "desc"
            ? (a, b) => descendingComparator(a, b, pattern)
            : (a, b) => -descendingComparator(a, b, pattern);
    };

    const FilterSelectStatus = () => {
        return (
            <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label-1">
                    Estados
                </InputLabel>
                <Select
                    disabled={filterBy === defaultValueFilterBy}
                    value={filterValue}
                    onChange={handleFilterChange}
                    autoWidth
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Estados" />}
                >
                    <MenuItem value={"CREATED"}>Creados</MenuItem>
                    <MenuItem value={"REVIEW"}>En revisión</MenuItem>
                    <MenuItem value={"REJECTED"}>Rechazados</MenuItem>
                    <MenuItem value={"sPaymentStatus"}>Pendientes de pago</MenuItem>
                    <MenuItem value={"IN_NOTARY"}>En notaría</MenuItem>
                    <MenuItem value={"SIGNED_PENDING"}>
                        Pendientes de firma
                    </MenuItem>
                    <MenuItem value={"FINISHED"}>Entregados</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const visibleRows = useMemo(() => {
        const filterData = rows.filter((row) => {

            if (filterValue === "sPaymentStatus") {

                return ["PENDING", "PARTIALLY_PAID"].includes(row["sStatus"]["sPaymentStatus"]);

            } else if (["sTipoContrato", "sTipoFirma"].includes(filterBy)) {
                return !verifiVariable(row["tramit"][filterBy])
                    ? row["tramit"][filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } else if (["dContractDT"].includes(filterBy)) {
                return !verifiVariable(row["information"][0][filterBy])
                    ? row["information"][0][filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } else if (["sStatus"].includes(filterBy)) {
                return !verifiVariable(row["sStatus"][filterBy])
                    ? row["sStatus"][filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } else if (["sContractName"].includes(filterBy)) {
                return !verifiVariable(row["sContractName"])
                    && row["sContractName"]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase());
            } else if (filterBy !== defaultValueFilterBy) {
                return !verifiVariable(row[filterBy])
                    ? row[filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } else {
                return row;
            }
        });

        const arrayData = stableSort(
            filterData,
            getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setCountRows(filterData.length);

        return arrayData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, rowsPerPage, rows, filterValue, filterBy]);

    useEffect(() => {
        if (!verifiVariable(dataGrid) && dataGrid.length > 0) {
            const arrayData = [];

            dataGrid.forEach((element) => {
                arrayData.push(
                    createData(
                        element["sProcess"],
                        element["sContractID"],
                        element["autoId"],
                        !verifiVariable(element["sContractName"]) ? element["sContractName"] : 'No especificado',
                        {
                            sTipoContrato: element["sTipoContrato"],
                            sTipoFirma: element["sTipoFirma"],
                        },
                        {
                            sStatus: element["sStatus"],
                            sPaymentStatus: element["sPaymentStatus"],
                        },
                        element["dContractDT"],
                        element["withDocument"],
                        element["iSignedCount"]
                    )
                );
            });

            setRows(arrayData);
        };
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    return (
        <Fragment>
            <DeleteContractAlert
                updateData={() => updateList()}
                sProcess={sProcess}
                contractID={idContract}
                openModalDelete={openModalDelete}
            />

            <Collapse in={show} orientation="vertical">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel id="demo-simple-select-autowidth-label">
                                Filtrar por
                            </InputLabel>
                            <Select
                                input={<OutlinedInput label="Filtrar por" />}
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={filterBy}
                                onChange={handleChange}
                                autoWidth
                                MenuProps={MenuProps}
                            >
                                <MenuItem value={"autoId"}>ID</MenuItem>
                                <MenuItem value={"sContractName"}>Nombre del contrato</MenuItem>
                                <MenuItem value={"sTipoContrato"}>Tipo trámite</MenuItem>
                                <MenuItem value={"sTipoFirma"}>Tipo firma</MenuItem>
                                <MenuItem value={"dContractDT"}>Fecha</MenuItem>
                                <MenuItem value={"sStatus"}>Estado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {filterBy !== "sStatus" && (
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <FilledInput
                                    disabled={filterBy === defaultValueFilterBy}
                                    placeholder="Buscar"
                                    value={filterValue}
                                    onChange={handleFilterChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton edge="end">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        )}

                        {filterBy === "sStatus" && <FilterSelectStatus />}
                    </Grid>
                </Grid>
            </Collapse>

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />

                    <TableBody>
                        {visibleRows.map((row) => (
                            <Rows
                                sProcess={sProcess}
                                key={row["autoId"]}
                                deleteContrat={(e) => {
                                    setOpenModalDelete((value) => 1 + value);
                                    setIdContract(e["sContractID"]);
                                    setSProcess(e["sProcess"]);
                                }}
                                row={row}
                            />
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { label: "Todos", value: -1 },
                                ]}
                                // colSpan={3}
                                count={countRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "filas por página",
                                    },
                                    native: true,
                                }}
                                labelRowsPerPage={"Trámites por página"}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => {
                                    return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`
                                        }`;
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Fragment>
    );
};

export default GeneralListProced;