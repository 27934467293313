import React from 'react';
import { FaRegClock, FaCheck } from "react-icons/fa";
import ScreenSizeHook from '../../hooks/ScreenSizeHook';

const Statustrackeritem = ({ title, focused, onClick, sStatus, subTile, from }) => {
    const screenSizeHook = ScreenSizeHook();

    const setTop =()=>{

        if(from === 'express') {
            if(['xs', 'sm', 'md'].includes(screenSizeHook)) return 30;
            return 0;
        }

        if(from === 'ANF') {
            if(['xs'].includes(screenSizeHook)) return 44;
            if(['sm', 'md'].includes(screenSizeHook)) return 30;
            return 0;
        }

        if(from === 'ROL') {
            if(['xs', 'md'].includes(screenSizeHook)) return 30;
            return 0;
        }

        return 45;
    }

    const displayStatus = () => {

        if (sStatus === 'REJECTED') {
            return "status--notdone";
        } else if (focused === true) {
            return "status--approved";
        } else if (focused === false) {
            return "status--inprogress";
        } else {
            return "status--notdone";
        }

    }

    const displayIcon = () => {

        if (sStatus === 'REJECTED') {
            return <FaRegClock className="icon--xxs" />
        } else if (focused === true) {
            return <FaCheck className="icon--xxs" />
        } else if (focused === false) {
            return <FaRegClock className="icon--xxs" />
        } else {
            return <FaRegClock className="icon--xxs" />
        }

    }

    const displayTitleStyle = () => {

        if (sStatus === 'REJECTED') {
            return "breadcrum-item--unfocus";
        } else if (focused === true) {
            return "breadcrum-item--focus";
        } else if (focused === false) {
            return "breadcrum-item--unfocus";
        } else {
            return "breadcrum-item--unfocus";
        }

    }

    const displayStatusStyle = () => {

        if (sStatus === 'REJECTED') {
            return "status-text--pending";
        } else if (focused === true) {
            return "status-text--approved";
        } else if (focused === false) {
            return "status-text--inprogress";
        } else {
            return "status-text--pending";
        }

    }

    const displayStatusText = () => {

        if (sStatus === 'REJECTED') {
            return 'Rechazado'
        } else if (focused === true) {
            return "Aprobado";
        } else if (focused === false) {
            return "En proceso";
        } else {
            return "Por realizar";
        }

    }

    return (
        <li className="flex--start status-tracker-item__container">
            <div className={`status-tracker__icon-container ${displayStatus()}`}>{displayIcon()}</div>
            <div className="flex--start flex--column flex--align-start">
                <strong className={`font-size--xxs ${displayTitleStyle()}`} onClick={onClick}>{title}</strong>
                <strong className={`font-size--xxs ${displayStatusStyle()}`}>{displayStatusText()}</strong>
                <p className='font-size--xxs position--absolute' style={{marginTop: setTop()}}>{subTile}</p>
            </div> 
        </li>
    );
}

export default Statustrackeritem;
