import axios from "axios";
import { api, verifiVariable } from "../utils/enums";

const uri = api.BASE_URI;
const scheduleUri = api.BASE_SCHEDULE_URI;
const ANFUri = process.env.REACT_APP_API_URI_ANF;
const userToken = process.env.REACT_APP_USUARIO_DEMO;
const clvToken = process.env.REACT_APP_PASSWORD_DEMO;

export const replaceDocumentANF = async (token, body) => {
    try {
        const response = await axios.post(
            `${ANFUri}/api/contract/replace/document`,
            body,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    };
};

export const replaceDocument = async (token, body) => {
    try {
        const response = await axios.post(
            `${uri}/api/v1/upfileB64`,
            body,
            {
                headers: {
                    Authorization: token,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    };
};

export const getTramitAnf = async (token, sContractID) => {
    try {
        const response = await axios.get(
            `${ANFUri}/api/contract/${sContractID}`,
            {
                headers: {
                    Authorization: token,
                    contentType: "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const loginDemo = async () => {
    try {
        const response = await axios.post(
            `${api.BASE_URI}/logindata`,
            {
                login: userToken,
                password: clvToken,
            },
            {
                headers: {
                    contentType: "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllNotaries = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_NOTARIES}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllNotarys = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_NOTARYS}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllJuridiction = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_JURIDICTIONS}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAllCommune = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_COMMUNE}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const checkRutBusiness = async (userToken, data) => {
    try {
        const response = await axios.post(
            `https://merge.firmavirtual.com/checkRut`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const contractsType = async () => {
    try {
        const response = await axios.post(
            `${uri}${api.CONTRACT_TYPES}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const contractStoreFilesRequest = async (
    sContractID,
    fileBase64,
    userToken
) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPLOAD_FILE}`,
            {
                document: {
                    sContractID,

                    file_content: fileBase64,
                },
            },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        const { status } = response;

        if (status === "fail") throw new Error("Fallo al subir el documento");

        return response;
    } catch (error) {
        return error;
    }
};

/* export const contractStoreFilesRequest = async (files, userToken) => {
    try {
        const response = await axios.post(`${uri}${api.UPLOAD_FILE}`, files, {
            headers: {
                Authorization: userToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
}; */

export const contractCreationRequest = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.CONTRACT_CREATION}`,
            contract,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response;
    } catch (error) {
        return error;
    }
};

export const resetPassword = async (user) => {
    try {
        const response = await axios.post(
            `${uri}${api.RESET_PASS}/${user}`,
            {},
            {
                headers: {
                    contentType: "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const setNewPassword = async (user, code, password) => {
    try {
        const response = await axios.post(
            `${uri}${api.NEW_PASS}`,
            {
                login: user,
                password: code,
                new_password: password,
            },
            {
                headers: {
                    contentType: "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const setNewDocsOrder = async (contract, files, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SORT_CONTRACT_FILES}`,
            {
                contractID: contract,
                file_order: files,
            },
            {
                headers: {
                    Authorization: userToken,
                    contentType: "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteDocs = async (contract, files, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_CONTRACT_FILES}`,
            {
                contractID: contract,
                file_delete: files,
            },
            {
                headers: {
                    Authorization: userToken,
                    contentType: "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const mergeDocs = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.MERGE_CONTRACT_FILES}/${contract}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const upaloadContractNotaryDoc = async (data, userToken) => {
    return axios.post(`${uri}${api.UP_NOTARY_DOC}`, data, {
        headers: {
            Authorization: userToken,
        },
    });
};

export const deleteContractDoc = async (userToken, idContrac) => {
    return axios.delete(`${uri}${api.DELETE_CONTARCT_DOC}/${idContrac}`, {
        headers: {
            Authorization: userToken,
        },
    });
};

export const sendSignerEmail = async (userToken, data) => {

    const response = await axios.post(`${uri}/api/v2/contract/whatsapp`, data, {
        headers: {
            Authorization: userToken,
        },
    });

    return response["data"];
};

export const downloadContractDoc = async (file, userToken) => {
    try {
        const response = await axios.get(
            `${uri}${api.DOWNLOAD_CONTRACT_FILE}/${file}`,
            {
                responseType: "blob",
                headers: {
                    Authorization: userToken,
                },
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            file.includes(".pdf") ? file : `${file}.pdf`
        );
        document.body.appendChild(link);
        link.click();

        return response.data;
    } catch (error) {
        return error;
    }
};

export const downloadANFContractDoc = async (contract, view) => {
    try {
        const response = await axios.get(`${ANFUri}/api/download/${contract}`, {
            responseType: "blob",
        });

        if (view) {
            const resultFile = new File([response.data], "tramit", {
                type: "application/pdf",
            });
            return resultFile;
        } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `contrato_${contract}.pdf`);
            document.body.appendChild(link);
            link.click();

            return response.data;
        }
    } catch (error) {
        return error;
    }
};

export const downloadCompiledDTE = async (contract, userToken, email) => {
    try {
        const response = await axios.post(
            `${uri}${api.DOWNLOAD_COMPILED_DTE}`,
            {
                contractId: contract,
            },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        const {
            data: { status, message },
        } = response;

        if (status === "success") {
            const payer = message.find((item) => item["email"] === email);

            window.open(payer["url"], "_blank");
        }

        return response;
    } catch (error) {
        return error;
    }
};

export const downloadCompiledContractDoc = async (contract, userToken, view) => {
    try {
        const {
            data: {
                code,
                message: { url, url_signed, url_log, url_notary },
            },
        } = await axios.get(
            `${uri}${api.DOWNLOAD_COMPILED_CONTRACT_FILE}/${contract}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        if (code === 200) {

            if (!verifiVariable(url_notary)) {
                window.open(url_notary, "_blank");
                return url_notary;
            }

            if (!verifiVariable(url_signed)) {
                window.open(url_signed, "_blank");
                return url_signed;
            }

            if (!verifiVariable(url_log)) {
                window.open(url_log, "_blank");
                return url_log;
            }

            if (!verifiVariable(url)) {
                window.open(url, "_blank");
                return url;
            }

            /* if (
                [
                    "APPROVED",
                    "REJECTED",
                    "CREATED",
                    "REVIEW",
                    "PENDING",
                    "SIGNED_PENDING",
                    "PAIDOUT",
                    "PARTIALLY_PAID",
                ].includes(view)
            )
                window.open(url, "_blank");

            if (["IN_NOTARY"].includes(view)) {
                if (
                    [
                        verifiVariable(url_signed),
                        verifiVariable(url_log),
                    ].includes(true)
                ) {
                    window.open(url, "_blank");
                } else {
                    window.open(url_signed, "_blank");
                    window.open(url_log, "_blank");
                }
            }

            if (["FINISHED"].includes(view)) {
                if ([verifiVariable(url_notary)].includes(true)) {
                    window.open(url_signed, "_blank");
                } else {
                    window.open(url_notary, "_blank");
                }
            }

            return url; */
        }
    } catch (error) {
        return error;
    }
};

export const sendUserContractEmail = async (
    rutId,
    contract,
    body,
    userToken
) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_CONTRACT_EMAIL}/${contract}/${rutId}`,
            body,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const sendEmailSign = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_EMAIL_SIGN(contract)}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const resetContractApi = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.RESET_CONTRACT(contract)}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const sendContractPriotity = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_PRIORITY(contract)}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const sendContractEmail = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SEND_CONTRACT_EMAIL}/${contract}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteContract = async (contract, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_CONTRACT}/${contract}`,
            null,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateMemberInfoApiCall = async (
    contract,
    rutId,
    userToken,
    info
) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPDATE_MEMBER_INFO}`,
            {
                contractID: contract,
                rutId: rutId,
                ...info,
            },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteMemberApiCall = async (contract, rutId, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_MEMBER}`,
            {
                contractID: contract,
                rutId: rutId,
            },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const allContracts = async (userToken) => {
    try {
        const response = await axios.get(`${uri}${api.GET_ALL_CONTRACT}`, {
            headers: {
                Authorization: userToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const allContractType = async (userToken) => {
    try {
        const response = await axios.get(`${uri}${api.GET_ALL_CONTRACT_TYPE}`, {
            headers: {
                Authorization: userToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const createAgreement = async (agreement, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.AGREEMENT_CREATION}`,
            agreement,
            {
                headers: {
                    Authorization: userToken,
                    contentType: "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getOrganizations = async (userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.GET_ALL_ORGANIZATIONS}`,
            {},
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getDetailAgreement = async (organization, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.GET_AGREEMENT_DETAIL}`,
            { organization },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response;
    } catch (error) {
        return error;
    }
};

export const deleteAgreement = async (organization, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.DELETE_AGREEMENT}`,
            { organization },
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};
