import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";

import Grid from "@mui/material/Grid";
import _ from "lodash";

import { SignUpAccountForm } from "../../components";
import { store } from "../../store/storeConfig";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import { apiMessage, to } from "../../utils/enums";
import { resetSignup } from "../../store/auth/userSignUp";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import { useSelector } from "react-redux";
import ModalAlert from "../../components/floats/ModalAlert";
// import HelpIconSpeedDial from "../../components/helpIconSpeedDial";
// import WhatsappIconSpeedDial from "../../components/whatsappIconSpeedDial";

export default function SignUpAccount() {
    const screenSizeHook = ScreenSizeHook();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [errorOnSignUp, setErrorOnSignUp] = useState(false);
    const [modalStatus, setModalStatus] = useState({});
    const [emailDominio, setEmailDominio] = useState(undefined);
    // eslint-disable-next-line
    const [urlTab, setUrlTab] = useState("");

    const {
        session: { signup },
    } = useSelector((state) => state);

    // eslint-disable-next-line
    const formatUrl = (searchString) => {
        switch (searchString) {
            case "@gmail":
                setUrlTab("https://mail.google.com");
                break;

            case "@proton":
                setUrlTab("https://account.proton.me");
                break;

            case "@outlook":
                setUrlTab("https://login.live.com");
                break;

            case "@hotmail":
                setUrlTab("https://login.live.com");
                break;

            case "@mailbox":
                setUrlTab("https://login.mailbox.org");
                break;

            default:
                setUrlTab(`mailto:${emailDominio}`);

                break;
        }
    };

    const messageModalError = (code) => {
        switch (code) {
            case 440:
                return "El correo electrónico no es válido. Por favor, asegúrate de ingresar una dirección de correo válida.";
            case 441:
                return "El RUT no es válido. Asegúrate de ingresar un RUT válido.";
            case 443:
                return "El correo electrónico ya existe. Por favor, utiliza un correo electrónico diferente.";
            case 444:
                return "El RUT ya existe. Por favor, utiliza un RUT diferente.";
            case 446:
                return "El nombre no es válido. Asegúrate de ingresar un nombre válido, con un máximo de 30 caracteres.";
            case 447:
                return "El apellido no es válido. Asegúrate de ingresar un apellido válido, con un máximo de 30 caracteres.";
            case 449:
                return "Contraseña inválida. Debe tener al menos 6 caracteres alfanuméricos, incluyendo números, letras y los caracteres especiales: [!_@$*+-.=:#?&%^]. Inténtalo nuevamente.";
            case 451:
                return "El RUT es obligatorio. Por favor, ingresa tu RUT.";
            case 452:
                return "El correo electrónico es obligatorio. Por favor, ingresa tu correo electrónico.";
            case 454:
                return "La contraseña es obligatoria. Por favor, ingresa una contraseña.";
            case 455:
                return "El teléfono es obligatorio. Por favor, ingresa tu número de teléfono.";
            case 456:
                return "El número de teléfono no es válido. Asegúrate de ingresar un número de teléfono válido.";
            case 10:
                return "Se produjo un error al realizar tu registro. Por favor, inténtalo de nuevo más tarde. Contacte con nuestro soporte";
            case 20:
                return "Valida tu registro ingresando el enlace que te enviamos a tu correo electrónico. Si no recibiste el correo, por favor revisa la carpeta de spam o ponte en contacto con nuestro equipo de soporte.";
            case null:
                return "";
            default:
                return "Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.";
        }
    };

    const handleModal = () => {
        if (errorOnSignUp) {
            setModalStatus({
                title: "",
                message: "",
                buttonText: "",
                modalIcon: ""
            });
            setShowModal(false);
            store.dispatch(resetSignup());
        } else {
            store.dispatch(resetSignup());
            navigate(to.HOME);
        }
    };

    useEffect(() => {
        //console.log({ signup })
        (() => {
            if (!_.isEmpty(signup)) {
                if (signup.status === apiMessage.SUCCESS) {
                    setErrorOnSignUp(false);
                    setModalStatus({
                        title: "¡Listo!",
                        message: "Revisa tu correo electrónico para activar cuenta.",
                        buttonText: "Aceptar",
                        modalIcon: "emailIcon"
                    });
                    setShowModal(true);

                    setEmailDominio(signup["message"][0]["userApp"]["sEmail"]);

                    /* [
                        "@gmail",
                        "@proton",
                        "@outlook",
                        "@hotmail",
                        "@mailbox",
                    ].forEach((word) => {
                        const findWord =
                            signup["message"][0]["userApp"]["sEmail"].search(
                                word
                            );
 
                        if (findWord !== -1) formatUrl(word);
                    }); */
                }

                /* if (signup.status === apiMessage.FAIL) {
                    setErrorOnSignUp(true);
                    setModalTitle("¡Ups!, Algo ha pasado");
                    setModalMsg(10);
                    setModalButtonTitle("Volver a intentarlo");
                    setShowModal(true);
                } */

                if (signup.error) {
                    console.log(signup)
                    setErrorOnSignUp(true);
                    setModalStatus({
                        title: "¡Ups!, Algo ha pasado",
                        message: messageModalError(signup.code),
                        buttonText: "Volver a intentarlo",
                        modalIcon: "warnIcon"
                    });
                    setShowModal(true);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signup]);

    return (
        <Fragment>

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            <div className="login-wrapper">
                <div className="start-session__container">
                    <div className="start-session-content__container flex--center flex--column">
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sx={{ marginRight: 2, marginLeft: 2 }}
                            >
                                <Link to="/">
                                    <Logo
                                        className="logo-size--md"
                                        style={{ width: "100%" }}
                                    />
                                </Link>
                            </Grid>
                        </Grid>

                        <h1 className="title--head text-align--center title">
                            La aventura comienza aquí
                        </h1>

                        <Outlet />

                        <SignUpAccountForm />
                    </div>
                </div>

                {!["xs", "sm"].includes(screenSizeHook) && (
                    <div className="showcase__container login-bg" />
                )}
            </div>

            {/* <WhatsappIconSpeedDial /> */}

            {/* <HelpIconSpeedDial /> */}
        </Fragment>
    );
}
