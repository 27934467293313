import { createTheme/* , responsiveFontSizes */ } from "@mui/material/styles";

let theme = createTheme({
    /* typography: {
        fontFamily: [
            '"PoppinsRegular"',
            '"PoppinsBold"'
        ].join(',')
    }, */
    palette: {
        customBlueLight: {
            main: "#00adea"
        },
        white: {
            main: "#FFFFFF",
        },
        black: {
            main: "#000000",
            secondary: "#434343"
        },
        gray: {
            main: "#808080",
            dark: "#A9A9A9",
            light: "#D3D3D3",
            xlight: "#F8F8F8",
            xxlight: "#B1B1B1",
            xxxlight: '#F3F3F3'
        },
        primary: {
            main: "#004f9f",
            light: "#00adea",
            xlight: "#6ACEF1",
            xxlight: "#EEF1FB",
            xxxlight: "#AAD1DE",
            xxxxlight: "#EEFBFF",
            xxxxxlight: "#F0F6FF",
            xxxxxxlight: '#CCE6FF',
            dark: "#003c77",
            xdark: "#0080FF"
        },
        secondary: {
            main: "#ff326e",
            xlight: "#fff5f8",
            light: "#f86262",
            dark: "#cf3966",
        },
        tertirary: {
            main: "#fa8724",
            xlight: "#fff8dd",
            light: "#ffb676",
            dark: "#db6d0d",
        },
        quaternary: {
            main: "#5cc858",
            xlight: "#e8fff3",
            light: "#87e483",
            dark: "#4ca149",
        },
        quinary: {
            main: "#b4bacc",
            light: "#f6f9ff",
            dark: "#a0aac7",
            xdark: "#747c91",
        },
        senary: {
            main: "#7239ea",
            light: "#9667fd",
            dark: "#4e289e",
            xdark: "#372266",
        },
        success: {
            main: "#5cc858",
        },
        error: {
            main: "#cf3966",
            light: "#F5EFEF",
            dark: "#FF8282"
        },
        info: {
            main: "#ffb676",
        },
        warning: {
            main: "#f86262",
            light: "#F1C299",
            xlight: "#FFF2E5",
            dark: "#FA8724"
        },
        customFormButtomSolicitud: {
            main: '#f39201'
        }
    },
    spacing: 8,
});

// theme = responsiveFontSizes(theme);

export { theme };
