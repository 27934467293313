import { useContext } from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ReactComponent as UpdateIconV1 } from "../../assets/icons/update-icon-v1.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { Context } from "../../context/utilsContext";
import { useLocation } from "react-router-dom";
import { to } from "../../utils/enums";

const ActionsWhiteButton = styled(Button)((props) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.white.main,
    color: props.theme.palette.primary.light,
    fontFamily: ["PoppinsBold"].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.white.main,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.white.main,
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const ActionsBlueButton = styled(Button)((props) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    color: props.theme.palette.white.main,
    backgroundColor: props.theme.palette.primary.light,
    fontFamily: ["PoppinsBold"].join(","),
    "&:hover": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.primary.light,
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.primary.light,
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        backgroundColor: props.theme.palette.primary.light,
    },
}));

const CustomToobarTableList = ({ onUpdate, onCreate, title, subTitle, onDisableCreate }) => {
    const { setShow } = useContext(Context);
    const { pathname } = useLocation();

    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        flexDirection: "column",
                    }}
                >
                    <Typography fontFamily={"PoppinsBold"}>{title}</Typography>
                    <Typography
                        variant={"caption"}
                        fontFamily={"PoppinsRegular"}
                    >
                        {subTitle}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "space-between",
                    }}
                >
                    {pathname !== to.CONTRACT_APROVED && (
                        <ActionsWhiteButton
                            startIcon={<FilterIcon />}
                            onClick={() => setShow()}
                        >
                            Filtros
                        </ActionsWhiteButton>
                    )}

                    <ActionsWhiteButton
                        startIcon={<UpdateIconV1 />}
                        onClick={() => onUpdate()}
                    >
                        Actualizar
                    </ActionsWhiteButton>

                    <ActionsBlueButton disabled={onDisableCreate} onClick={() => onCreate()}>
                        Crear trámite
                    </ActionsBlueButton>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CustomToobarTableList;
