import { useEffect, useState } from "react";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import PropTypes from "prop-types";
import { GeneralListProcedStyles } from "../../index.css";

const arrayHeadCells = [
    {
        id: "Acciones",
        numeric: false,
        disablePadding: true,
        label: "Acciones",
    },
    {
        id: "ID",
        numeric: false,
        disablePadding: false,
        label: "ID",
    },
    {
        id: "Nombre del contrato",
        numeric: false,
        disablePadding: false,
        label: "Nombre del contrato",
    },
    {
        id: "Tramite",
        numeric: false,
        disablePadding: false,
        label: "Trámite",
    },
    {
        id: "Fecha",
        numeric: false,
        disablePadding: false,
        label: "Fecha",
    },
];

const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
    const styles = GeneralListProcedStyles();
    const screenSizeHook = ScreenSizeHook();
    const [headCells, setHeadCells] = useState([]);

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    useEffect(() => {
        if (screenSizeHook === "xs") {
            setHeadCells(
                [...arrayHeadCells].filter((item) => item["id"] !== "Estatus")
            );
        } else {
            setHeadCells(arrayHeadCells);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSizeHook]);

    return (
        <TableHead className={styles.tableHead}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell> */}
                {[...headCells].map((headCell) => (
                    <TableCell
                        className={styles.cellTitle}
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                    /* padding={
                        headCell.disablePadding ? "none" : "normal"
                    } */
                    // sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "desc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null} */}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;