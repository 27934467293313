import { forwardRef, Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaExclamationCircle } from "react-icons/fa";
import { BsExclamationTriangle } from "react-icons/bs";
import { FaCloudArrowDown } from "react-icons/fa6";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";
import Chip from "@mui/material/Chip";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CachedIcon from "@mui/icons-material/Cached";

import { ReactComponent as UserIcon } from "../../img/user-icon.svg";
import { ReactComponent as CardIcon } from "../../img/card-icon.svg";
import { ReactComponent as DocReadyToDownload } from "../../img/doc-ready-to-download.svg";
import { ReactComponent as AcceptedDocument } from "../../img/accepted-document.svg";
import { ReactComponent as RejectedDocument } from "../../img/rejected-document.svg";
import { ReactComponent as DocumentToAccept } from "../../img/documento-por-aceptar.svg";
import { ReactComponent as SignedIcon } from "../../img/signed-icon.svg";
import { ReactComponent as UnsignedIcon } from "../../img/unsigned-icon.svg";
import { ReactComponent as RequiredIconStatus } from "../../img/esperando-preaprobacion.svg";
import { ReactComponent as DisagreeIconStatus } from "../../img/preaprobacion-rechazada.svg";
import { ReactComponent as AgreeIconStatus } from "../../img/preaprobacion-aceptada.svg";

import ResendUserContract from "./molecules/resendUserContract";
import { DetailSignatoriesStyles } from "./index.css";
import EditParticipant from "../editParticipant";
import {
    api,
    docStatus,
    typeUser,
    verifiVariable,
} from "../../../../../utils/enums";
import RejectCommentsModal from "../rejectCommentsModal";
import { theme } from "../../../../../theme/theme";
import { Context } from "../../../../../context/utilsContext";
import {
    aprovedMemberContract,
    downloadMemberContractDoc,
    unAprovedMemberContract,
} from "../../../detailSignatories/services";
import { downloadCompiledDTE } from "../../../../../utils/api";

const styledSignatureStatus = { zoom: "1.6", marginTop: "6px" };

const stylesStatuSign = {
    marginTop: "6px",
    zoom: "1.6",
};

const stylesPreApproverStatus = {
    marginTop: "6px",
    zoom: "1.4",
};

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomTitle = styled(Typography)((props) => ({
    lineHeight: "1.2",
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

const DetailSignatories = ({
    comentarios,
    member,
    index,
    contractDetail,
    contractMembers,
    contractFiles,
    removeSignner,
    deleteSigner,
    updateContract,
    loading,
    textLoading,
}) => {
    const url = `${api.BASE_URI}/webpay/create/${member["token_payment"]}/${contractDetail[0]["sContractID"]}`;
    const styles = DetailSignatoriesStyles();
    const {
        session: {
            login: { userInfo, user },
        },
    } = useSelector((state) => state);
    const { loading: loading1, setLoading } = useContext(Context);
    // eslint-disable-next-line
    const [statusContract, setStatusContract] = useState(undefined);
    const [contract, setContract] = useState(undefined);
    const [expanded, setExpanded] = useState(false);
    // eslint-disable-next-line
    const [textBox, setTextBox] = useState("");
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [titleErrorAlert, setTitleErrorAlert] = useState(undefined);
    const [msnErrorAlert, setMsnErrorAlert] = useState(undefined);

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTitleErrorAlert(undefined);
        setMsnErrorAlert(undefined);
    };

    const descargarDTE = async (email, sContractID) => {
        try {
        } catch (error) { }
        const { token } = user;

        const {
            data: { status },
        } = await downloadCompiledDTE(sContractID, token, email);

        if (status === "fail") {
            setTitleErrorAlert("Archivo no disponible.");
            setMsnErrorAlert(
                "No se puede descargar el archivo porque no existe."
            );

            setOpen(true);
        }
    };

    const downloadContract = async () => {
        try {
            setLoading(true);

            const { token } = user;

            await downloadMemberContractDoc(
                member["token_download"],
                token,
                contractDetail[0]["sContractID"]
            );

            setLoading(false);

            updateContract();
        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }
    };

    const unAprovedDoc = async () => {
        try {
            setLoading(true);

            const { token } = user;

            await unAprovedMemberContract(member["token_disagree"], token);

            setLoading(false);

            updateContract();
        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }
    };

    const aprovedDoc = async () => {
        try {
            setLoading(true);

            const { token } = user;

            await aprovedMemberContract(member["token_agree"], token);

            setLoading(false);

            updateContract();
        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }
    };

    useEffect(() => {
        if (contractDetail) {
            setStatusContract(contractDetail[0].sStatus);
            setContract(contractDetail[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractDetail]);

    useEffect(() => {
        if ([4, 2].includes(member["rol"])) {
            setStatusText("Pre aprobador");
        }
        if ([5].includes(member["rol"])) {
            setStatusText("Copia");
        }
        if ([1, 0].includes(member["rol"])) {
            setStatusText("Firmante");
        }

        const splitText = member["full_name"].split(" ");

        let newText = "";

        splitText.forEach((texto, index) => {
            if (index !== 3)
                newText += `${texto.substring(0, 1).toUpperCase()}`;
        });

        setTextBox(newText);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    return (
        <Fragment>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
            >
                <Alert
                    className={styles.muiAlert}
                    onClose={handleCloseSnackbar}
                    severity="success"
                >
                    El link de pago ha sido copiado, puedes enviárselo a el
                    firmante.
                </Alert>
            </Snackbar>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                className={styles.mainContainerDialog}
            >
                <DialogTitle sx={{ textAlign: "center", color: "red" }}>
                    {!verifiVariable(titleErrorAlert)
                        ? titleErrorAlert
                        : "Ah ocurrido un error!"}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {!verifiVariable(msnErrorAlert) ? (
                            msnErrorAlert
                        ) : (
                            <Fragment>
                                Lo sentimos, ah ocurrido un error. Le pedimos
                                disculpas por las molestias causadas y lo
                                invitamos a reportar el error a nuestro equipo
                                de soporte desde el siguiente enlace.
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    onClick={() => {
                                        window.open(
                                            "https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0",
                                            "_blank"
                                        );
                                        handleClose();
                                    }}
                                >
                                    <FaWhatsapp
                                        className="icon--white"
                                        style={{
                                            fontSize: 28,
                                            fill: "#5cc858",
                                        }}
                                    />
                                </IconButton>
                            </Fragment>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <div className={styles.mainContainer}>
                <Card sx={{ width: 300 }} className={styles.cardContainer}>
                    <Tooltip
                        title={
                            <Typography
                                variant="subtitle1"
                                color={["white.main"]}
                                gutterBottom
                            >
                                Actualizar datos del participante.
                            </Typography>
                        }
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                    >
                        <IconButton
                            aria-label="settings"
                            sx={{
                                alignSelf: "flex-end",
                            }}
                            onClick={() => updateContract()}
                        >
                            <CachedIcon className="icon--blue-light" />
                        </IconButton>
                    </Tooltip>

                    <UserIcon />

                    <CustomTitle
                        sx={{ fontSize: "1.1rem", marginTop: "15px" }}
                        color={["primary.light"]}
                    >
                        Participante {1 + index}
                    </CustomTitle>

                    <CustomTitle
                        sx={{ fontSize: "1.1rem" }}
                        color={["black.main"]}
                    >
                        {!verifiVariable(member) ? member["full_name"] : ""}
                    </CustomTitle>

                    {
                        <div className={styles.statusHeader}>
                            {/* statusPayment */}

                            {member["portion"] !== "0.00" && (
                                <Fragment>
                                    {member["payment"] === "PENDING" && (
                                        <div className={styles.statusPay}>
                                            <FaExclamationCircle className="icon--blue" />

                                            <CustomTitle
                                                sx={{ fontSize: "0.95rem" }}
                                                color={["primary.main"]}
                                            >
                                                Pendiente de pago
                                            </CustomTitle>
                                        </div>
                                    )}

                                    {member["payment"] === "PAYOUT" && (
                                        <div className={styles.statusPay}>
                                            <FaExclamationCircle className="icon--blue-light" />

                                            <CustomTitle
                                                sx={{ fontSize: "0.95rem" }}
                                                color={["primary.light"]}
                                            >
                                                Pagado
                                            </CustomTitle>
                                        </div>
                                    )}
                                </Fragment>
                            )}

                            {/* contractMembersSignatureStatus */}

                            {[1, 0].includes(member["rol"]) && (
                                <Fragment>
                                    {member["download_draft"] ===
                                        docStatus.ACTIVE &&
                                        member["agree_draft"] ===
                                        docStatus.NOT_ACTIVE &&
                                        member["disagree_draft"] ===
                                        docStatus.NOT_ACTIVE && (
                                            <div
                                                className="flex--center"
                                                style={styledSignatureStatus}
                                            >
                                                <DocReadyToDownload />
                                            </div>
                                        )}

                                    {member["download_draft"] ===
                                        docStatus.SUCCESS &&
                                        member["agree_draft"] ===
                                        docStatus.ACTIVE &&
                                        member["disagree_draft"] ===
                                        docStatus.ACTIVE && (
                                            <div
                                                className="flex--center"
                                                style={Object.assign(
                                                    {
                                                        ...styledSignatureStatus,
                                                    },
                                                    { zoom: "1.5" }
                                                )}
                                            >
                                                <DocumentToAccept />
                                            </div>
                                        )}

                                    {member["download_draft"] ===
                                        docStatus.SUCCESS &&
                                        member["agree_draft"] ===
                                        docStatus.SUCCESS &&
                                        member["disagree_draft"] ===
                                        docStatus.NOT_ACTIVE && (
                                            <div
                                                className="flex--center"
                                                style={styledSignatureStatus}
                                            >
                                                <AcceptedDocument />
                                            </div>
                                        )}

                                    {member["download_draft"] ===
                                        docStatus.SUCCESS &&
                                        member["agree_draft"] ===
                                        docStatus.NOT_ACTIVE &&
                                        member["disagree_draft"] ===
                                        docStatus.SUCCESS && (
                                            <div
                                                className="flex--center"
                                                style={styledSignatureStatus}
                                            >
                                                <RejectedDocument />
                                            </div>
                                        )}

                                    {(member["download_draft"] === undefined ||
                                        member["agree_draft"] === undefined ||
                                        member["disagree_draft"] ===
                                        undefined) && (
                                            <div className="flex--start status_tag status--pending">
                                                {
                                                    <BsExclamationTriangle className="icon--xs margin-right--sm" />
                                                }{" "}
                                                Sin información
                                            </div>
                                        )}
                                </Fragment>
                            )}

                            {/* statuSign */}

                            {[1, 0].includes(member["rol"]) && (
                                <Fragment>
                                    {(statusContract === "SIGNED_PENDING" ||
                                        statusContract === "DELIVERED" ||
                                        statusContract === "IN_NOTARY") &&
                                        member["sign_status"] === "SIGNED" && (
                                            <div
                                                className="flex--center"
                                                style={stylesStatuSign}
                                            >
                                                <SignedIcon />
                                            </div>
                                        )}

                                    {["DELIVERED", "SIGNED_PENDING"].includes(
                                        statusContract
                                    ) &&
                                        [
                                            "NOTHING",
                                            "NOTIFIED",
                                            "VIEW",
                                            "DECLINED",
                                            "BOUNCED",
                                            "FORWARDED",
                                            "CANCELLED",
                                        ].includes(member["sign_status"]) && (
                                            <div
                                                className="flex--center"
                                                style={stylesStatuSign}
                                            >
                                                <UnsignedIcon />
                                            </div>
                                        )}
                                </Fragment>
                            )}

                            {/* preApproverStatus */}

                            {[2, 4].includes(member["rol"]) && (
                                <Fragment>
                                    {!verifiVariable(contract) &&
                                        contract["sPreAprobado"] ===
                                        "REQUIRED" && (
                                            <div
                                                className="flex--center"
                                                style={stylesPreApproverStatus}
                                            >
                                                <RequiredIconStatus />
                                            </div>
                                        )}

                                    {!verifiVariable(contract) &&
                                        contract["sPreAprobado"] ===
                                        "BEFORE_DISAGREE" && (
                                            <div
                                                className="flex--center"
                                                style={stylesPreApproverStatus}
                                            >
                                                <DisagreeIconStatus />
                                            </div>
                                        )}

                                    {!verifiVariable(contract) &&
                                        contract["sPreAprobado"] ===
                                        "BEFORE_AGREE" && (
                                            <div
                                                className="flex--center"
                                                style={stylesPreApproverStatus}
                                            >
                                                <AgreeIconStatus />
                                            </div>
                                        )}
                                </Fragment>
                            )}
                        </div>
                    }

                    <CardActions disableSpacing style={{ marginTop: "65px" }}>
                        {loading1 && <CircularProgress />}
                        {/* TODO verificar si solo el owner puede aciones botones de aceptar y rechazar */}

                        {!loading1 &&
                            /* (!verifiVariable(userInfo) && userInfo['iProfileID'] === contractDetail[0]['iContractOwner']) && */
                            ["REVIEW", "CREATED", "REJECTED"].includes(
                                statusContract
                            ) &&
                            [0, 1, 4].includes(member["rol"]) &&
                            userInfo["iProfileID"] === typeUser.ADMIN && (
                                <Stack direction="row" spacing={1}>
                                    {contractDetail[0]["withDocument"] ===
                                        1 && (
                                            <IconButton
                                                onClick={() => downloadContract()}
                                            >
                                                <Tooltip title="Descargar" arrow>
                                                    <CloudDownloadOutlined className="icon--grey-dark-light" />
                                                </Tooltip>
                                            </IconButton>
                                        )}

                                    {[docStatus.SUCCESS].includes(
                                        member["download_draft"]
                                    ) &&
                                        [docStatus.ACTIVE].includes(
                                            member["disagree_draft"]
                                        ) &&
                                        [docStatus.ACTIVE].includes(
                                            member["agree_draft"]
                                        ) && (
                                            <Fragment>
                                                <IconButton
                                                    onClick={() =>
                                                        unAprovedDoc()
                                                    }
                                                >
                                                    <Tooltip
                                                        title="Rechazar"
                                                        arrow
                                                    >
                                                        <CancelOutlinedIcon className="icon--red" />
                                                    </Tooltip>
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => aprovedDoc()}
                                                >
                                                    <Tooltip
                                                        title="Aceptar"
                                                        arrow
                                                    >
                                                        <CheckCircleOutline className="icon--green" />
                                                    </Tooltip>
                                                </IconButton>
                                            </Fragment>
                                        )}
                                </Stack>
                            )}

                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            {!expanded && (
                                <Tooltip
                                    title={
                                        <Typography
                                            variant="subtitle1"
                                            color={["white.main"]}
                                            gutterBottom
                                        >
                                            Ver más.
                                        </Typography>
                                    }
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                >
                                    <VisibilityOutlinedIcon className="icon--blue-light" />
                                </Tooltip>
                            )}

                            {expanded && (
                                <Tooltip
                                    title={
                                        <Typography
                                            variant="subtitle1"
                                            color={["white.main"]}
                                            gutterBottom
                                        >
                                            Ver menos.
                                        </Typography>
                                    }
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    arrow
                                >
                                    <VisibilityOffOutlinedIcon className="icon--blue-light" />
                                </Tooltip>
                            )}
                        </ExpandMore>
                    </CardActions>

                    <Collapse
                        in={expanded}
                        timeout="auto"
                        unmountOnExit
                        className={styles.expandMoreContent}
                    >
                        <CardContent>
                            <List
                                sx={{
                                    width: "100%",
                                    /* maxWidth: 360, */ bgcolor:
                                        "background.paper",
                                }}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="RUT"
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {member["RUT"]}
                                                </Typography>

                                                <Divider />
                                            </Fragment>
                                        }
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Correo electrónico"
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {member["email"]}
                                                </Typography>

                                                <Divider />
                                            </Fragment>
                                        }
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Número de celular"
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {member["phone"]}
                                                </Typography>

                                                <Divider />
                                            </Fragment>
                                        }
                                    />
                                </ListItem>

                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary="Rol"
                                        secondary={
                                            <Fragment>
                                                {member["rol"] !== 3 && (
                                                    <Chip
                                                        label={
                                                            <Typography
                                                                sx={{
                                                                    display:
                                                                        "inline",
                                                                }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {statusText}
                                                            </Typography>
                                                        }
                                                    />
                                                )}

                                                {member["portion"] !==
                                                    "0.00" && (
                                                        <Chip
                                                            sx={{
                                                                marginLeft: "10px",
                                                            }}
                                                            label={
                                                                <Typography
                                                                    sx={{
                                                                        display:
                                                                            "inline",
                                                                    }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Pagador
                                                                </Typography>
                                                            }
                                                        />
                                                    )}

                                                <Divider />
                                            </Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Collapse>
                </Card>

                {!verifiVariable(contract) &&
                    [
                        "REVIEW",
                        "CREATED",
                        "REJECTED",
                        "APPROVED",
                        "FINISHED",
                        "SIGNED_PENDING",
                    ].includes(contract["sStatus"]) &&
                    !verifiVariable(contract) &&
                    [
                        "REQUIRED",
                        "BEFORE_DISAGREE",
                        "BEFORE_REVIEW",
                        "BEFORE_AGREE",
                        "NONE",
                    ].includes(contract["sPreAprobado"]) &&
                    !verifiVariable(contract) &&
                    ["PENDING", "PARTIALLY_PAID", "PAIDOUT"].includes(
                        contract["sPaymentStatus"]
                    ) && (
                        <div className={styles.contentActionButtoms}>
                            {/* CASOS BOTON COMENTARIOS */}

                            {["CREATED", "REJECTED", "REVIEW"].includes(
                                contract["sStatus"]
                            ) &&
                                !verifiVariable(comentarios) &&
                                comentarios.filter(
                                    (item) =>
                                        item["SignerName"] ===
                                        member["full_name"]
                                ).length >= 1 && (
                                    <div>
                                        <RejectCommentsModal
                                            member={member}
                                            index={index}
                                            comenmts={[...comentarios].filter(
                                                (item) =>
                                                    item["SignerName"] ===
                                                    member["full_name"]
                                            )}
                                        />
                                    </div>
                                )}

                            {/* CASOS BOTONES PRE APROBADORES */}
                            {[2].includes(member["rol"]) &&
                                ["CREATED"].includes(contract["sStatus"]) &&
                                [
                                    "REQUIRED",
                                    "BEFORE_DISAGREE",
                                    "BEFORE_REVIEW",
                                ].includes(contract["sPreAprobado"]) && (
                                    // (!verifiVariable(member) && member['download_draft'] !== docStatus.SUCCESS && member['agree_draft'] !== docStatus.SUCCESS) &&
                                    <Fragment>
                                        <div>
                                            <EditParticipant
                                                updateContract={() =>
                                                    updateContract()
                                                }
                                                member={member}
                                                contractMembers={
                                                    contractMembers
                                                }
                                                numParticipant={1 + index}
                                                idContract={
                                                    contractDetail[0][
                                                    "sContractID"
                                                    ]
                                                }
                                                loading={(e) => loading(e)}
                                                textLoading={(e) =>
                                                    textLoading(e)
                                                }
                                            />
                                        </div>

                                        <div
                                            style={{
                                                background:
                                                    theme.palette.primary.light,
                                            }}
                                        >
                                            <ResendUserContract
                                                updateContract={() =>
                                                    updateContract()
                                                }
                                                contractMembers={
                                                    contractMembers
                                                }
                                                contractFiles={contractFiles}
                                                member={member}
                                            />
                                        </div>
                                    </Fragment>
                                )}

                            {/* CASOS BOTONES FIRMANTES */}

                            {[0, 1, 4].includes(member["rol"]) &&
                                (["NONE"].includes(contract["sPreAprobado"])
                                    ? true
                                    : ["BEFORE_AGREE"].includes(
                                        contract["sPreAprobado"]
                                    )) &&
                                !verifiVariable(member) &&
                                (member["download_draft"] !==
                                    docStatus.SUCCESS ||
                                    member["agree_draft"] !==
                                    docStatus.SUCCESS) && (
                                    <Fragment>
                                        {["CREATED", "REVIEW"].includes(
                                            contract["sStatus"]
                                        ) && (
                                                <div>
                                                    <EditParticipant
                                                        updateContract={() =>
                                                            updateContract()
                                                        }
                                                        member={member}
                                                        contractMembers={
                                                            contractMembers
                                                        }
                                                        numParticipant={1 + index}
                                                        idContract={
                                                            contractDetail[0][
                                                            "sContractID"
                                                            ]
                                                        }
                                                        loading={(e) => loading(e)}
                                                        textLoading={(e) =>
                                                            textLoading(e)
                                                        }
                                                    />
                                                </div>
                                            )}

                                        {["REVIEW"].includes(
                                            contract["sStatus"]
                                        ) && (
                                                <div
                                                    style={{
                                                        background:
                                                            theme.palette.primary
                                                                .light,
                                                    }}
                                                >
                                                    <ResendUserContract
                                                        updateContract={() =>
                                                            updateContract()
                                                        }
                                                        contractMembers={
                                                            contractMembers
                                                        }
                                                        contractFiles={
                                                            contractFiles
                                                        }
                                                        member={member}
                                                    />
                                                </div>
                                            )}
                                    </Fragment>
                                )}

                            {/* CASOS BOTON PAGADOR */}

                            {[3, 4, 0].includes(member["rol"]) &&
                                ["APPROVED"].includes(contract["sStatus"]) &&
                                ["PENDING", "PARTIALLY_PAID"].includes(
                                    contract["sPaymentStatus"]
                                ) &&
                                member["portion"] !== "0.00" && (
                                    <Tooltip
                                        title={
                                            <Typography
                                                variant="subtitle1"
                                                color={["white.main"]}
                                                gutterBottom
                                            >
                                                Pagar trámite.
                                            </Typography>
                                        }
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        arrow
                                    >
                                        <div
                                            style={{
                                                background:
                                                    theme.palette.primary.light,
                                            }}
                                        >
                                            <CopyToClipboard text={url}>
                                                <CardIcon
                                                    onClick={() => {
                                                        handleClickSnackbar();
                                                        window.open(
                                                            url,
                                                            "_blank"
                                                        );
                                                    }}
                                                    className="icon--lg"
                                                />
                                            </CopyToClipboard>
                                        </div>
                                    </Tooltip>
                                )}

                            {[3, 4, 0].includes(member["rol"]) &&
                                ["PAIDOUT"].includes(
                                    contract["sPaymentStatus"]
                                ) &&
                                member["portion"] !== "0.00" && (
                                    <Tooltip
                                        title={
                                            <Typography
                                                variant="subtitle1"
                                                color={["white.main"]}
                                                gutterBottom
                                            >
                                                Descargar DTE
                                            </Typography>
                                        }
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        arrow
                                    >
                                        <div
                                            style={{
                                                background:
                                                    theme.palette.primary.light,
                                            }}
                                        >
                                            <FaCloudArrowDown
                                                style={{ color: "white" }}
                                                onClick={() => {
                                                    descargarDTE(
                                                        member["email"],
                                                        contract["sContractID"]
                                                    );
                                                }}
                                                className="icon--lg"
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                        </div>
                    )}
            </div>
        </Fragment>
    );
};

export default DetailSignatories;
