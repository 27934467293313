import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../theme/theme';

export const DocumentsStyles = makeStyles(() => {

    return createStyles({
        conatinerCloseButtomPreview: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        FilePreview: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '244px',
            height: '354px',
            background: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            marginRight: '30px',
            marginTop: '20px',

            '& div:nth-child(3)': {
                width: '244px !important',
                height: '354px !important',

                '& object:first-child, img:first-child': {
                    height: '332px !important'
                }
            },

            '& embed': {
                width: '244px !important',
                height: '332px !important'
            }

        },
        fileMergePreview: {
            width: '158px',
            height: '229px',
            backgroundColor: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '30px',
            marginTop: '20px',

            '& div:nth-child(3)': {
                width: '158px !important',
                height: '229px !important',
                backgroundColor: '#EAF7FB !important',

                '& object:first-child, img:first-child': {
                    height: '135px !important'
                },

                "& div": {
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'unset !important',
                    paddingTop: 'unset !important',
                    paddingLeft: 'unset !important'
                }
            }

        },
        folderIconContainer: {
            width: '158px',
            height: '229px',
            background: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: '20px'
        },
        uploadButtomContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        fileButomsContainer: ({ screenSizeHook }) => ({
            width: '100% !important',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: screenSizeHook === 'xs' ? 'column' : 'row',
            flexWrap: 'wrap',
            marginLeft: '45px',
            marginRight: '45px'
        }),
        formContainer: {
            marginLeft: '45px',
            marginRight: '45px'
        },
        procedureCreationFormContainer: {
            justifyContent: 'flex-start !important'
        },
        buttomsLoadPdf: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        fileNameStyles: {
            color: theme.palette.black.main,
            fontWeight: "500"
        },
        buttonSubmitStyles: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "right",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        titleHintStyle: {
            color: theme.palette.quinary.xdark,
            fontWeight: "500",
            fontSize: "1.1rem",
            marginTop: "21px"
        },
        fileForm: {
            flex: "1"
        },
        fileFormContainer: {
            display: "flex",
            flexWrap: "wrap"
        },
        hintBodyButtonText: {
            fontSize: '0.875rem',
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            marginTop: "10px",
            "&:hover": {
                color: theme.palette.black.main,
                backgroundColor: "transparent"
            },
        },
        hintBodyButton: {
            fontSize: '0.875rem',
            backgroundColor: theme.palette.primary.light,
            padding: '6px 10px',
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            marginTop: "10px"
        },
        hintBodyLinkText: {
            margin: '0px 3px'
        },
        hintListItem: {
            display: "block"
        },
        hintListContainer: {
            margin: '10px 0px'
        },
        titleStyles: {
            color: theme.palette.black.main,
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        },
        subTitleStyles: {
            fontSize: "1rem !important"
        },
        bottomTitleStyles: {
            color: theme.palette.primary.main,
        },
        hintContainer: {
            padding: '15px 25px 35px',
            marginRight: '10px',
            backgroundColor: theme.palette.primary.xlight,
            borderRadius: '5px',
            flex: 1
        },
        hintStyles: {
            color: theme.palette.black.main,
            fontWeight: "300",
            marginBottom: "0"
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '44px'
        },
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        }
    });

});