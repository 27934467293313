import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { verifiVariable } from "../../../utils/enums";

export const ExpressProcessStyles = makeStyles((theme) => {
    return createStyles({
        mainContent: {
            display: "flex",
            flexGrow: 1,
            margin: "16px",
        },
        document: ({ document }) => ({
            paddingTop: !verifiVariable(document) ? "0px" : "",
        }),
        /* contenItem: {
            paddingTop: "75px",
        }, */

        /* stepContainerActionsButtoms: {
            width: `100%`,
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "80px",
            flexDirection: "row",
            paddingLeft: "45px",
            paddingRight: "45px",
        }, */

        contentDocuments: {
            display: "flex",
            flexDirection: "column",
            "& div.actions-buttoms": {
                marginBottom: "65px",
            },
        },
        title: {
            color: theme.palette.black.main,
            fontFamily: "PoppinsBold",
            fontSize: "0.95rem",
        },
        subTitle: {
            color: theme.palette.black.main,
            fontFamily: "PoppinsBold",
            fontSize: ".8rem",
        },
        textRadioButtom: {
            marginBottom: "0px",
            fontSize: "0.95rem",
            fontFamily: "PoppinsRegular",
        },
        spacinContent: {
            marginLeft: "45px",
            marginRight: "45px",
        },
        /* formItem: {
            boxShadow: "12px 0px 39px rgba(156, 174, 191, 0.2)",
            borderRadius: "7px",
        }, */
        infoItem: {
            paddingRight: "15px",
            paddingLeft: "15px",
            boxShadow: "unset",
            borderRadius: "0px 7px 7px 0px",
            "& > div": {
                marginBottom: "35px",
            },
        },
        titleFV: {
            marginBottom: "55px",
            fontFamily: "PoppinsBold",
            textAlign: "center",
            "& > span": {
                fontFamily: "PoppinsBold",
                fontStyle: "italic",
                fontSize: "1.5rem",
                color: theme.palette.primary.light,
            },
        },
        welcomeOptionsContent: {
            display: "flex",
            justifyContent: "space-evenly",
            "&  > div": {
                display: "flex",
                flexDirection: "column",
                width: "151px",
                alignItems: "center",
                " & > svg": {
                    width: "80px",
                    height: "80px",
                    marginBottom: "17px",
                },
                " & > h6": {
                    fontSize: "0.90rem",
                    fontFamily: "PoppinsBold",
                    textAlign: "center",
                },
            },
        },
        contactUs: {
            paddingTop: "26px",
            textAlign: "center",
            " & > h6": {
                fontFamily: "PoppinsBold",
            },
            "& h6:first-child": {
                fontSize: "0.90rem",
            },
            "& h6:nth-child(2)": {
                fontSize: "0.65rem",
                color: theme.palette.black.main,
            },
            "& > span": {
                fontSize: "0.70rem",
            },
        },
        titleAddDiag: {
            color: theme.palette.primary.light,
            fontFamily: "PoppinsBold",
            fontSize: "1rem",
            alignSelf: "flex-start",
        },
        titleFormAddDiag: {
            marginBottom: ".5rem",
            "& > h6": {
                color: theme.palette.black.main,
                fontFamily: "PoppinsBold",
                fontSize: "0.81rem",
            }
        },
        buttomsRol: {
            justifyContent: "space-around",
            "& label": {
                marginLeft: "0px",
                marginRight: "0px",

                "& span": {
                    padding: "1px",
                },
            },
        },
        rolIcon: {
            display: 'flex',
            /* justifyContent: 'center',
            "& > .css-j204z7-MuiFormControlLabel-root": {
                "& > span": {
                    padding: '0 .25rem 1rem .25rem'
                }
            }, */
        },
        contentFomAddpart: {
            width: "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 23px 0px 23px",
            margin: "45px 13px 37px 13px",
        },
        modalHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '2.5rem',
            alignItems: 'center',
            position: 'relative',
            "& > .closeIcon": {
                position: 'absolute',
                top: '-2.5rem',
                right: '-2rem'
            }
        },
        emailInput: {
            "& input, div": {
                borderRadius: "7px",
            },
        },
        errorText: {
            textAlign: "right",
        },
        deleteIcon: {
            marginRight: "0px !important",
        },
        navigationControls: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
        },
        pdfViewer: {
            alignSelf: "center",
            borderStyle: "solid",
            borderColor: theme.palette.primary.light,
            borderRadius: "5px",
            "& svg.delete-icon": {
                position: "relative",
                top: "10px",
                right: "12px",
                float: "right",
                cursor: "pointer",
                fill: "red",
            },
        },
        contentTracker: {
            padding: "unset !important",
            overflow: "unset !important",
        },
        contractDetailsHeader: {
            backgroundColor: "transparent !important",
            borderRadius: "0px !important",
            marginBottom: "0px !important",
        },
        numberTitle: {
            display: "flex",
            alignItems: "center",
            margin: '55px 0 25px 0',
            "& h6": {
                lineHeight: "1.2",
                "& span": {
                    color: "#A5A5A5",
                    fontFamily: "PoppinsRegular",
                },
            },
        },
        infoOwner: {
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
            "& svg": {
                marginRight: "20px",
            },
            "& > div:nth-child(2)": {
                marginRight: "50px",
            },
            "& h6": {
                fontSize: "0.85rem",
                fontFamily: "PoppinsBold",
            },
            "& p": {
                fontSize: "0.9rem",
                fontFamily: "PoppinsRegular",
                color: "#434343",
            },
        },
        stepNumbers: {
            display: "flex",
            width: "35px",
            height: "35px",
            justifyContent: "center",
            borderRadius: "7px",
            alignItems: "center",
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.light,
            fontSize: "1.2rem",
            fontFamily: "PoppinsBold",
        },
        detailTextTitle: {
            fontFamily: "PoppinsBold",
            fontSize: "0.90rem",
            marginLeft: "15px",
        },
        detailTextDetail: {
            fontFamily: "PoppinsRegular",
            color: theme.palette.gray.main,
            fontSize: "0.85rem",
            marginLeft: "50px",
            marginTop: "10px",
        },
        orderInfo: {
            border: `1px solid ${theme.palette.primary.xxxlight}`,
            backgroundColor: theme.palette.primary.xxxxlight,
            borderRadius: '10px',
            padding: '1rem 1rem',

            "& h6": {
                fontSize: "0.85rem",
                fontFamily: "PoppinsBold",
            },

            "& .service-info": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            },

            "& .service-info-total": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }
        },
        infoDoc: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: 'column',
            alignItems: "center",
            border: `1px solid ${theme.palette.primary.xxxlight}`,
            backgroundColor: theme.palette.primary.xxxxlight,
            borderRadius: '10px',
            padding: '1rem 0',
            "& div:first-child": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "50px",
            },
            "& div:first-child svg": {
                marginRight: "5px",
            }
        },
        infoDoc_buttons: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        },
        buttonsDocTramit: {
            display: "flex",
            justifyContent: "center",
            "& svg": {
                cursor: "pointer",
            },
            "& svg:nth-child(2)": {
                marginLeft: "10px",
            },
        },
        optionsTramit: {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
        },
        buttonTramit: {
            fontFamily: "PoppinsBold",
            fontSize: "0.65rem",
            textTransform: "unset !important",
            border: `1px solid ${theme.palette.primary.xlight}`,
            borderRadius: "5px",
            height: '2.25rem',
            width: '11.5rem',
            backgroundColor: theme.palette.white.main
        },
        buttonTramit2: {
            fontFamily: "PoppinsBold",
            fontSize: "0.65rem",
            textTransform: "unset !important",
            border: `none`,
            borderRadius: "5px",
            backgroundColor: theme.palette.primary.light,
            height: '2.25rem',
            width: '11.5rem',
            color: theme.palette.white.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            }
        },
        detailTextNameDoc: {
            marginLeft: "0px",
            fontSize: "0.70rem",
            color: theme.palette.primary.main
        },
        bottonTitle: {
            marginBottom: "0px",
            marginTop: "100px",
            fontSize: "0.75rem",
            "& span": {
                fontSize: "0.9rem",
                textDecoration: "underline",
            },
        },
        contactInfo: {
            textAlign: "center",
            paddingBottom: "40px",
            "& span": {
                color: theme.palette.gray.main,
            },
        },
        mainContentParticipants: ({ screenSizeHook }) => ({
            maxHeight: screenSizeHook === "sm" ? "815px" : "740px",
            overflowY: "scroll",
            "& h6": {
                lineHeight: "1.2",
                fontFamily: "PoppinsBold",
                fontSize: "1rem",
            },
            "& div": {
                borderRadius: "7px 7px 7px 7px",
            },
            "& :not(:first-child, button, h6, #textInfoParticipans)": {
                marginTop: "25px",
            },
        }),
        cardFirmUser: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "auto",
            padding: "0px",
            "& > div#textInfoParticipans": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "0px",
            },
            "& svg": {
                marginBottom: "12px",
            },
            "& :first-child": {
                color: theme.palette.primary.light + " !important",
            },
            "& h6": {
                color: theme.palette.black.main,
            },
            /* "& :nth-child(2)": {
                color: theme.palette.black.main,
            }, */
        },
        textXs: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            padding: "0px !important",
            "& h5": {
                marginBottom: "0px",
            },
        },
        cardTypeSignatureXs: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "160px",
            padding: "0px !important",
            "& h6": {
                marginLeft: "15px",
            },
            "& :nth-child(2)": {
                marginTop: "40px",
                fontFamily: "PoppinsRegular",
                color: "#434343",
            },
        },
        cardDocumentXs: {
            height: "220px",
            padding: "0px !important",
            paddingRight: "15px",
            "& h6:first-child": {
                paddingTop: "40px",
                marginLeft: "15px",
            },
            "& :nth-child(2)": {
                display: "flex",
                justifyContent: "flex-start",
                cursor: "pointer",
                alignItems: "baseline",
                margin: "0px",
                fontFamily: "PoppinsRegular",
                color: "#434343",
                "& svg": {
                    marginLeft: "15px",
                    marginRight: "5px",
                },
            },
            "& :nth-child(3)": {
                marginTop: "38px !important",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "0px",
                "& svg": {
                    marginTop: "0px !important",
                },
                "& svg:first-child": {
                    marginRight: "7px",
                },
            },
        },
        contentFooterXs: {
            height: "160px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& :not(span)": {
                fontFamily: "PoppinsBold",
            },
            "& h6:first-child": {
                fontSize: "1.15rem",
                color: theme.palette.primary.main,
                marginBottom: "10px",
            },
            "& :nth-child(2)": {
                color: theme.palette.black.main,
                fontSize: "0.8rem",
            },
        },
        appBarBottom: {
            position: "fixed",
            top: "auto",
            bottom: 0,
            zIndex: "unset",
            background: theme.palette.white.main,
            "& .MuiTabs-root": {
                width: "100%",
            },
            "& button": {
                fontSize: "0.66rem",
                textTransform: "unset !important",
                fontFamily: "PoppinsBold",
                color: theme.palette.primary.main,
            },
        },
        mainContainerDialog: {
            "& div.MuiPaper-root": {
                borderRadius: "10px",
            },
        },
        statusPay: {
            display: "flex",
            alignItems: "center",
            marginTop: "5px !important",
            "& > svg": {
                marginRight: "3px",
                marginBottom: "2px",
            },
            "& p": {
                marginTop: "0px !important",
            },
        },
        signatoriesList: {
            display: "flex",
            justifyContent: "center",
        },
        dragableBox: ({ layerX, layerY }) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "baseline",
            position: "absolute",
            left: `${layerX}px`,
            top: `${layerY}px`,
            background: "transparent",
            cursor: "move",
            "& p": {
                fontSize: "2rem",
            },
        }),
        boxIcon: {
            position: "absolute",
            bottom: "43px",
            fill: "red",
            left: "60px",
            zIndex: 999,
            cursor: "pointer",
        },
        textIcon: ({ iconColor }) => ({
            fill: iconColor,
        }),
        modalDocsContent: ({ screenSizeHook }) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            height: "auto",
            backgroundColor: theme.palette.white.main,
            border: "2px solid #000",
            boxShadow: 24,
            "& > div.body-modal-content": {
                overflow: "auto",
                width: screenSizeHook === "xs" ? "auto" : "500px",
                height: screenSizeHook === "xs" ? "80vh" : "500px",
            },
        }),
        fileButomsContainer: ({ screenSizeHook }) => ({
            width: "100% !important",
            display: "flex",
            justifyContent: "center",
            flexDirection: screenSizeHook === "xs" ? "column" : "row",
            flexWrap: "wrap",
        }),
        fileMergePreview: ({ screenSizeHook }) => ({
            width: "158px",
            height: "229px",
            backgroundColor: "#EAF7FB",
            border: "1px dashed #00ADEA",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: screenSizeHook === "xs" ? "0px" : "30px",
            marginTop: "20px",
            "& div:nth-child(3)": {
                width: "158px !important",
                height: "229px !important",
                backgroundColor: "#EAF7FB !important",

                "& object:first-child, img:first-child": {
                    height: "135px !important",
                },

                "& div": {
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'unset !important',
                    paddingTop: 'unset !important',
                    paddingLeft: 'unset !important'
                }
            },
        }),
        conatinerCloseButtomPreview: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
        },
        folderIconContainer: {
            width: "158px",
            height: "229px",
            background: "#EAF7FB",
            border: "1px dashed #00ADEA",
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            marginTop: "20px",
        },
        contentActionParticipants: {
            width: "100%",
            display: "flex",
            margin: "0px 0px 0px 0px !important",
            padding: "0px 16px 0px 16px",
            "& svg": {
                marginBottom: "5px",
                marginTop: "5px !important",
                fill: theme.palette.white.main,
            },
        },
        stepContainerActionsButtoms: {
            width: `100%`,
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "80px",
            flexDirection: "row",
            paddingLeft: "45px",
            paddingRight: "45px",
        },
        managProc: {
            paddingBottom: "45px",
            flexDirection: "column",
            alignItems: "center",
            "& h6": {
                textAlign: "center",
                fontFamily: "PoppinsBold",
                fontSize: "0.9rem",
            },
        },
        contenItem: {
            paddingTop: "40px",
        },
        formItem: {
            boxShadow: "12px 0px 39px rgba(156, 174, 191, 0.2)",
            borderRadius: "7px",
        },
        roleItem: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0',
            paddingBottom: '1rem'
        },
        containerHeader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '1rem 0',
            border: `solid 1px ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.primary.xxlight,
            '& > .box-icon': {
                padding: '0 .5rem 0 1rem'
            },
            '& > .box-text': {
                padding: '0 1rem 0 .5rem',
                textAlign: 'justify',
                '& > p': {
                    fontSize: '0.8rem',
                    fontFamily: 'PoppinsRegular',
                    '& > span': {
                        fontSize: '0.8rem',
                        fontFamily: 'PoppinsBold',
                        color: theme.palette.black.main
                    }
                }
            }
        }
    });
});